import React from 'react'
import { Accordion } from 'react-bootstrap'

import {
  AccordionWrapper,
  MainHeader
} from 'pages/graficos/components/date-separator/styles'
import { useFetch } from 'hooks'
import { convertToMoney } from 'functions'
import { Paciente, Repasse } from '.'
import { Loading } from 'pages/graficos/components'

interface BodyProps {
  date: string
  count: number
  Link: string
  monetary_values: string
  evk: number
}

const Body = ({ date, count, Link, monetary_values, evk }: BodyProps) => {
  const [data] = useFetch(Link)

  return data?.data ? (
    <>
      <Accordion.Toggle
        as={MainHeader}
        eventKey={`${evk}`}
        style={{ paddingLeft: '10px' }}
      >
        <span>{date.split('-').reverse().join('/')}</span>
        <div>{count} Repasses</div>
        <span></span>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${evk}`}>
        {data ? (
          <AccordionWrapper>
            <Repasse data={data?.data} />
          </AccordionWrapper>
        ) : (
          <Loading loading />
        )}
      </Accordion.Collapse>
    </>
  ) : (
    <>
      <Accordion.Toggle
        as={MainHeader}
        eventKey={`${evk}`}
        style={{ paddingLeft: '10px' }}
      >
        <span>{date.split('-').reverse().join('/')}</span>
        <div>{count} Atendimentos</div>
        <span style={{ color: '#44c767' }}>
          {monetary_values === "0.00" ? null : convertToMoney(monetary_values)}
        </span>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${evk}`}>
        {data ? (
          <AccordionWrapper>
            {data.map((i, j) => (
              <Paciente key={j} ev={j} {...i} />
            ))}
          </AccordionWrapper>
        ) : (
          <Loading loading />
        )}
      </Accordion.Collapse>
    </>
  )
}

export default Body
