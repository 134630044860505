import React, { useState, useLayoutEffect, useEffect } from 'react'
import Camera from './camera'
import Nota from './nota'
import InsertProduction from './insert-production'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { FaPlus } from 'react-icons/fa'

import * as S from './styles'

const Widgets = () => {
  const [opacity, setOpacity] = useState(1)
  const [lastScrollPos, setLastScrollPos] = useState(0)

  useLayoutEffect(() => {
    window.addEventListener('scroll', updateScreen)
  }, [])

  function updateScreen() {
    const st = window.pageYOffset || document.documentElement.scrollTop

    if (st > lastScrollPos) {
      setLastScrollPos(st)
      setOpacity(opacity - 0.75)
    } else {
      setOpacity(1)
      setLastScrollPos(st - 100)
    }
  }

  return (
    <S.Container>
      <Fab
        mainButtonStyles={{ backgroundColor: '#387fba', outline: 'none' }}
        icon={<FaPlus />}
        event={'click'}
        alwaysShowTitle={true}
      >
        <InsertProduction />
        <Camera />
        <Nota />
      </Fab>
    </S.Container>
  )
}

export default Widgets
