import styled from 'styled-components'

export const ButtonComponent = styled.button`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  font-size: 1.3rem;
  background-color: #1abc9c;
  color: #fff;
  outline: none !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  font-weight: 500;
  cursor: pointer;
  margin-right: 3px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  align-items: center;
`

export const Container = styled.div`
  position: fixed;
  bottom: 50px;
  right: 0;
  transform: translate(-50%, -50%);
  display: flex;
`
export const FAB = styled.button`
  background-color: #387fba;
  outline: none;
`
