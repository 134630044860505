/**
 * @desc converte string e números em valor monetário, ex.:
 * 1 = R$ 1,00
 *
 * @param {strign | number} value valor que vai ser tornada em valor monetário
 * @returns
 */
function convertToMoney(value) {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  try {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  } catch (err) {
    return (0).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  }
}

export function moneyRender(money) {
  if (money.value === undefined) {
    return
  }

  let m = money.value === undefined ? '' : money.value

  if (m === undefined || null) {
    return
  }

  return convertToMoney(m)
}

export default convertToMoney
