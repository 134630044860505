/* eslint-disable @typescript-eslint/no-this-alias */
window.resize = (function () {
  'use strict'

  function Resize() {
    //
  }

  Resize.prototype = {
    init: function (outputQuality) {
      this.outputQuality = outputQuality === 'undefined' ? 1.5 : outputQuality
    },

    photo: function (file, maxSize, outputType, callback) {
      const _this = this

      const reader = new FileReader()
      reader.onload = function (readerEvent) {
        _this.resize(readerEvent.target.result, maxSize, outputType, callback)
      }
      reader.readAsDataURL(file)
    },

    resize: function (dataURL, maxSize, outputType, callback) {
      const _this = this

      const image = new Image()
      image.onload = function (imageEvent) {
        // Resize image
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height
            height = maxSize
          }
        }
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)

        _this.output(canvas, outputType, callback)
      }
      image.src = dataURL
    },

    output: function (canvas, outputType, callback) {
      switch (outputType) {
        case 'file':
          canvas.toBlob(
            function (blob) {
              callback(blob)
            },
            'image/jpeg',
            0.8
          )
          break

        case 'dataURL':
          callback(canvas.toDataURL('image/jpeg', 1.5))
          break
      }
    }
  }

  return Resize
})()
