import { AxiosResponse } from 'axios'

import { MasterClass } from './master-class'
import { getData } from '../get-data'
import axios from '../../utils/axiosAPI'

interface InterfaceGet {
  IDMedico?: number
  mes?: string | number
  ano?: string | number
  competencia?: string
}

class Repasses extends MasterClass {
  async get({ IDMedico, mes, ano, competencia }) {
    let Repasses: IRepasse[] = []
    let url = this.getURL('repasse')
    const params = [IDMedico, mes, ano, competencia]
    const values = ['IDMedico', 'mes', 'ano', 'competencia']

    params.map((i, j) => {
      if (typeof i === 'undefined') return

      url += url.includes('?') ? '&' : '?'
      url += Object.values(values)[j] + '=' + i
    })

    await getData(url)
      .then(res => {
        Repasses = res as IRepasse[]
      })
      .catch(() => {
        throw new Error('Erro ao buscar repasses')
      })

    return Repasses as IRepasse[]
  }

  /**
   *
   * @desc Busca toda a produção (procedimentos) de um repasse
   *
   * @param {Number} IDRepasse
   */
  async producao(IDRepasse: number) {
    return await getData(`/repasse/${IDRepasse}/producao`)
      .then((data: any) => {
        return data || []
      })
      .catch(err => {
        throw new Error(err)
      })
  }

  lastDate(year: number, month: number) {
    return new Date(year, month, 0).getDate()
  }

  async getByCompetencia(mes: string, ano: string | number) {
    return await getData(
      `/repasse?DataRepasseInicial=${ano}-${mes}-01&DataRepasseFinal=${ano}-${mes}-${this.lastDate(
        ano as number,
        parseInt(mes)
      )}`
    ).catch(e => {
      throw new Error(e)
    })
  }

  async getNotaFiscal(IDRepasse: number | string) {
    return await getData(`repasse/${IDRepasse}/nota_fiscal`)
  }

  async getProducao(IDRepasse: number | string) {
    return await getData(`repasse/${IDRepasse}/producao`)
  }
}

export default new Repasses()
