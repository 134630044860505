/**
 *
 * @param {IConvenio} convenio
 * @param {Number} max
 */
function siglaConvenio(convenio, max = 8) {
  let sigla = ''
  let paramSigla = ''

  if (!convenio) {
    return
  }

  if ('Convenio' in convenio) {
    paramSigla = 'Convenio'
  }

  if ('SiglaConvenio' in convenio) {
    paramSigla = 'SiglaConvenio'
  }

  if ('Hospital' in convenio) {
    paramSigla = 'Hospital'
  }

  if ('Descricao' in convenio) {
    paramSigla = 'Descricao'
  }

  if (paramSigla === '') {
    return
  }

  /*
  if(convenio[paramSigla] === null) {
    const nome = convenio.Convenio;

    sigla = nome.match(/\b(\w)/g).join('.');
  } else {
    sigla = convenio[paramSigla];

    if(sigla.length > max) {
      const _s = sigla.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');

      if(_s.length < max / 3) {
        sigla = sigla.substr(0,max + 9);
      } else {
        sigla = _s
      }
    }
  }
  */

  sigla = convenio[paramSigla].substring(0, 9)

  if (convenio[paramSigla].length > 9) {
    sigla += '...'
  }

  if (typeof sigla === 'object') {
    sigla = sigla[0]
  }

  return sigla
}

export default siglaConvenio
