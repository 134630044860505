import React, { useEffect, useState } from 'react'
import { Accordion, Card, Modal, ModalBody } from 'react-bootstrap'
import { BiImage } from 'react-icons/bi'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai'
import { FiRotateCcw, FiRotateCw } from 'react-icons/fi'

import {
  AccordionComponent,
  PatientDetails,
  PatientInfo
} from 'pages/graficos/components/date-separator/styles'
import { useFetch } from 'hooks'
import { convertToMoney } from 'functions'
import { FilaDeImagens } from 'functions/network'
import { IFilaImagem } from 'functions/@types/fila-de-imagens'
import { ImageComponent } from './styles'

interface PacienteProps {
  Paciente: string
  ValorOrcado: string
  ValorProcedimentos: string
  Link: string
  ev: number
}

const Paciente = ({
  Paciente,
  ValorOrcado,
  ValorProcedimentos,
  Link,
  ev
}: PacienteProps) => {
  const [data] = useFetch(Link)
  const [guia, setGuia] = useState<IFilaImagem[]>([])
  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)
  const onShow = () => setShow(true)
  const [rotate, setRotate] = useState(0)

  function rotateCw() {
    let newRotation = rotate + 90
    if (newRotation >= 360) {
      newRotation = -360
    }
    setRotate(newRotation)
  }

  function rotateCcw() {
    let newRotation = rotate - 90
    if (newRotation >= 360) {
      newRotation = -360
    }
    setRotate(newRotation)
  }

  React.useEffect(() => {
    console.log(data)
  }, [data])

  useEffect(() => {
    if (typeof data?.data?.ID === 'undefined') {
      return
    }
    FilaDeImagens.get(data.data?.ID).then(res => setGuia(res))
  }, [data])

  return (
    <Card.Body style={{ margin: 0, padding: 0 }}>
      <Accordion.Toggle as={AccordionComponent} eventKey={`ev-${ev}`}>
        <span>{Paciente}</span>

        <span style={{ color: '#44c767' }}>
          {ValorProcedimentos ? null : convertToMoney(ValorProcedimentos)}
        </span>
      </Accordion.Toggle>
      <PatientInfo eventKey={`ev-${ev}`}>
        <>
          <PatientDetails style={{ margin: 0, padding: 0 }}>
            <p>
              <strong>Convênio</strong>
              <span>{data?.data.Convenio?.Convenio}</span>
            </p>

            <p>
              <strong>Status</strong>
              <span> {data?.data?.Status?.Status}</span>
            </p>

            {data?.data?.ValorOrcado !== '0.00' && (
              <p>
                <strong>Valor Tabela</strong>
                <span>{convertToMoney(data?.data?.ValorOrcado)}</span>
              </p>
            )}

            <p>
              <strong>Procedimento</strong>
              <span style={{ textAlign: 'right' }}>
                {data?.data?.Procedimento?.Descricao || ''}
              </span>
            </p>

            <p>
              <strong>Paciente</strong>
              <span>{data?.data?.Paciente}</span>
            </p>
            {guia.length > 0 && (
              <p>
                <strong>Guia Imagens</strong>
                {guia?.map((i, j) => (
                  <>
                    <span
                      key={j}
                      style={{ fontSize: '24px' }}
                      onClick={() => onShow()}
                    >
                      <BiImage />
                    </span>
                    <Modal {...{ show, onHide }} centred>
                      <Modal.Header {...{ onHide }} closeButton>
                        <Modal.Title>Guia</Modal.Title>
                      </Modal.Header>
                      <ModalBody>
                        <ImageComponent>
                          <TransformWrapper
                            velocityAnimation={{ disabled: false }}
                            limitToBounds={true}
                          >
                            {({ zoomIn, zoomOut, resetTransform }) => (
                              <React.Fragment>
                                <AiOutlineZoomIn onClick={() => zoomIn()} />{' '}
                                <AiOutlineZoomOut onClick={() => zoomOut()} />{' '}
                                <FiRotateCcw onClick={() => rotateCcw()} />{' '}
                                <FiRotateCw onClick={() => rotateCw()} />
                                <TransformComponent>
                                  <img
                                    style={{
                                      transform: `rotate(${rotate}deg)`
                                    }}
                                    src={`${i.URL}.jpg`}
                                  />
                                </TransformComponent>
                              </React.Fragment>
                            )}
                          </TransformWrapper>
                        </ImageComponent>
                      </ModalBody>
                    </Modal>
                  </>
                ))}
              </p>
            )}
          </PatientDetails>

          {data?.data?.ProcedimentosComplementares.length > 0 && (
            <div style={{ marginLeft: 15, marginTop: 7 }}>
              <strong style={{ fontSize: 14 }}>Procedimentos:</strong>

              <ul style={{ margin: 0, padding: 0, fontSize: 14 }}>
                {data?.data?.ProcedimentosComplementares.map(i => (
                  <li
                    key={i.ID}
                    style={{ listStyle: 'none', margin: 0, padding: 0 }}
                  >
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <strong>Procedimento:</strong>
                      <span style={{ textAlign: 'right' }}>
                        {i.Procedimento.Descricao}
                      </span>
                    </p>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <strong>Valor Tabela:</strong>
                      <span>{convertToMoney(i.Valor)}</span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      </PatientInfo>
    </Card.Body>
  )
}

export default Paciente
