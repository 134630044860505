export abstract class MasterClass {
  private IDCliente: number
  private is_admin: boolean

  constructor() {
    if (typeof window !== 'undefined') {
      try {
        const udata = localStorage.getItem('udata')
        const json = JSON.parse(udata || '')
        this.is_admin = json.Cliente === 0
        this.IDCliente = this.is_admin ? json.IDCliente : json.Cliente
      } catch (err) {
        this.IDCliente = 0
        this.is_admin = false
      }
    } else {
      this.IDCliente = 0
      this.is_admin = false
    }
  }

  protected getIDCliente() {
    return this.IDCliente
  }

  protected getIsAdmin() {
    return this.is_admin
  }

  public setCliente({ IDCliente }): void {
    if (this.getIsAdmin()) {
      this.getIDCliente = IDCliente
    }
  }

  protected getURL(url: string): string {
    return `/${url}${
      this.getIsAdmin() ? `?IDCliente=${this.getIDCliente()}` : ''
    }`
  }
}
