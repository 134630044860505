import styled from 'styled-components'
import { Accordion, Card } from 'react-bootstrap'
import { IoMdArrowDropright } from 'react-icons/io'

export const CardComponent = styled(Card.Body)`
  margin: 0;
  padding: 0;
`

export const AccordionComponent = styled(Card.Header)`
  font-size: 0.9rem;
  font-weight: 400;
  padding: 10px 2px 10px 10px;
  line-height: 1.5rem;
  background-color: #f7f7f7;
  border: none;
  display: flex;
  justify-content: space-between;

  .price {
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--green);
    border-color: var(--green);
    line-height: 1.5rem;
  }
`

export const AccordionWrapper = styled(Accordion)`
  background-color: #f7f7f7;
  border: none;
`

export const PatientInfo = styled(Accordion.Collapse)`
  background-color: #f7f7f7;
  border: none;
`

export const PatientDetails = styled(Card.Body)`
  font-size: 0.8rem;
  margin-left: 15px;
  margin-right: 28px;
  border-left: 1px solid #000;
  border-image: linear-gradient(90deg, #fff 0, #3a3a3a 40%, #3a3a3a 60%, #fff) 1
    20%;
  padding: 0;

  p {
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
    padding-left: 15px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
`

export const MainHeader = styled(Card.Header)`
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  div {
    color: var(--blue);
    padding: 4px;
    font-size: 0.9rem;
    font-weight: 600;
  }
`

export const DateSeperatorComponent = styled(Accordion)`
  position: relative;
  clear: both;
  border: 0;
  border-style: solid;
  border-radius: 5px;
  border-image: linear-gradient(
      90deg,
      #fff 0,
      hsla(0, 0%, 49%, 0.15) 40%,
      hsla(0, 0%, 49%, 0.15) 60%,
      #fff
    )
    1 20%;
  background-color: var(--lighter-gray);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`

/**
 *
 * @desc Seta a qual mostra se o acordion está "ativo"
 *
 * @param {boolean} isCurrentEventKey
 */
export const ArrowComponent = styled(IoMdArrowDropright)`
  margin-top: 4px;
  margin-right: 4px;
  transition: 0.3s;
  rotate: ${props => (props.isCurrentEventKey ? '90deg' : '0deg')};
`
