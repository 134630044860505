import React, { useState, useEffect } from 'react'
import { Container, Card } from 'react-bootstrap'
import DataGrid, { Column, MasterDetail } from 'devextreme-react/data-grid'

import * as S from './styles'
import * as C from './components'
import { Loading } from '../graficos/components'
import { MonthYear } from '../../components'
import axios from '../../utils/axiosAPI'
import { isMobile } from 'react-device-detect'
import { moneyRender } from 'functions'

const ExtratoMedico = () => {
  const [data, setData] = useState<IRepasse[] | any>([])
  const [loading, setLoading] = useState(false)
  const lastday = function (y, m) {
    return new Date(y, m, 0).getDate()
  }

  async function getRepasses({ month, year }) {
    setLoading(true)
    const url = `/repasse?perPage=100&DataRepasseInicial=${year}-${month}-01&DataRepasseFinal=${year}-${month}-${lastday(
      year,
      month
    )}`
    // const url = `/repasse?DataRepasseInicial=${2020}-${12}-01&DataRepasseFinal=${2020}-${12}-${lastday(2020,12)}`
    await axios
      .get(url)
      .then(res => {
        setData(res.data.data)
      })
      .catch(console.error)
    setLoading(false)
  }

  return (
    <S.ExtratoComponent>
      <Container>
        <Card>
          <Card.Header>
            <MonthYear
              onChange={e => {
                getRepasses(e)
              }}
            />
          </Card.Header>
          <Card.Body>
            <Loading {...{ loading }} />
            <C.ResumoRepasse
              data={[...data]}
              gridChildren={<MasterDetail enabled component={SubGrid} />}
            />
          </Card.Body>
        </Card>
      </Container>
    </S.ExtratoComponent>
  )
}

function SubGrid({ data }) {
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<IProducao[]>([])

  useEffect(() => {
    getDetails(data.key.ID)
  }, [data])

  async function getDetails(ID: number) {
    setLoading(true)
    const values: any[] = await axios
      .get(`/repasse/${ID}/producao`)
      .then((res: any) => {
        return res.data.data
      })
      .catch(alert)

    const v = await values.map(async i => {
      return await axios
        .get(`/producao/${i.IDProducao}`)
        .then(res => {
          return res.data.data
        })
        .catch(console.warn)
    })

    Promise.all(v).then(setDetails).catch(console.warn)
    setLoading(false)
  }

  return (
    <DataGrid
      dataSource={details}
      columnHidingEnabled={isMobile}
      showBorders={true}
      allowColumnResizing={true}
      hoverStateEnabled={true}
      columnAutoWidth
      style={{ width: '100%' }}
    >
      <Loading {...{ loading }} />
      <Column dataField="Paciente" caption="Paciente" />
      <Column dataField="Data" dataType="date" caption="Data Atendimento" />
      <Column dataField="Procedimento.Sigla" caption="Procedimento" />
      <Column dataField="Convenio.Convenio" caption="Convênio" />
      <Column dataField="Hospital.Hospital" caption="Local Atendimento" />
      <Column
        dataField="ValorOrcado"
        caption="Valor Tabela"
        cellRender={moneyRender}
      />
    </DataGrid>
  )
}

export default ExtratoMedico
