import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

export const FAB = styled.div`
  font-family: 'Rajdhani-Bold';
`

export const ModalComponent = styled(Modal)`
  font-family: 'ProximaNova' !important;
`

export const ModalTitleComponent = styled(ModalComponent.Title)`
  font-family: 'Rajdhani-Bold';
`

export const ModalComponentBody = styled(ModalComponent.Body)``

export const NF = styled.div`
  font-family: 'Rajdhani-Medium';
  font-size: 20px;
  .ab {
    margin-top: 9px;
  }
`

export const NFList = styled.div`
  .ab {
    @media (min-width: 320px) and (max-width: 375px) {
      font-size: 18px !important;
      margin-top: 12px !important;
    }
    @media (max-width: 320px) {
      font-size: 15px !important;
      margin-top: 17px !important;
    }
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    background-color: #4bc56b;
    border-color: #4bc56b;
    border-radius: 0.25rem;
    color: #fff;
    outline: none;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 6px 6px;
    margin-left: 10px;

    @media (min-width: 320px) and (max-width: 400px) {
      font-size: 16px;
    }
    @media (max-width: 320px) {
      font-size: 14px;
      padding: 6px 5px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 25px;
  }

  li {
    background-color: rgba(214, 214, 214, 0.5);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
  }
  h5 {
    color: #5f6f7e;
  }
  h5:hover {
    color: #1ea9e3;
  }
  li:last-child {
    margin-bottom: 0px;
  }
  .nome {
    margin-top: 8px;
    padding: 0px;
    font-size: 18px;
    display: flex !important;
    @media (min-width: 375px) and (max-width: 400px) {
      font-size: 16px;
    }
    @media (min-width: 320px) and (max-width: 375px) {
      font-size: 16px;
    }
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  .icon {
    color: red;
    font-size: 20px;
    cursor: pointer;
  }
  .resposta {
    text-align: justify;
    color: #2f4f4f;
    font-family: Rajdhani-Regular;
    cursor: default !important;
  }
  .pergunta {
    padding-left: 10px;
    font-family: Rajdhani-SemiBold;
    cursor: pointer !important;

    @media (min-width: 320px) and (max-width: 375px) {
      font-size: 18px;
    }
    @media (max-width: 320px) {
      font-size: 16px;
    }
  }
`
