import styled from 'styled-components'

export const SearchComponent = styled.div`
  top: 56px;
  position: absolute;
  background: #e8e8e8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 80px;

  button {
    border-radius: 4px;
    height: 40px;
    margin-top: 20px;
  }

  input {
    margin-top: 20px;
    margin-left: 10px;
    height: 40px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: relative;
    display: inline-block;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .accordion {
    width: 100%;
    font-family: 'ProximaNova';
    font-size: 14px;
  }

  .col-7.left {
    padding-left: 0px;
  }

  .col-4.right {
    padding-left: 0px;
    padding-right: 0px;
    text-align: right;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;

    .col-6.left {
      font-family: 'ProximaNova-Bold';
      text-align: left;
      padding-left: 0px;
    }

    .col-6.right {
      text-align: right;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .card-body,
  .card-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .card {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .card:last-child {
    margin-bottom: 20%;
  }

  ul {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 25px;
    width: 100%;
  }

  li {
    background-color: rgba(214, 214, 214, 0.5);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
  }

  .result {
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 40px;
    color: #000;
    padding: 0px;
    overflow: auto;
    max-height: 65vh;

    /* ul{
      width: 100%;
      list-style-type: none;
      padding-inline-start: 0px;
    }
    
    li{
      background: #FFFFFF;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 40px;
      text-align: justify;
      padding-top: 8px;
      padding-left: 1.2rem;

    }  */
  }
`
