import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'

import * as D from './components'
import * as S from './styles'
import axios from '../../../../utils/axios'

const types = [
  {
    key: 'bar',
    value: 'Barra'
  },
  {
    key: 'area',
    value: 'Área'
  }
]

const ComparativoMensal = () => {
  const [data, setData] = useState([])
  const [categories, setCategories] = useState([])
  const [graphType, setGraphType] = useState('bar')

  useEffect(() => {
    const formData = new FormData()
    formData.append('IDMedico', localStorage.getItem('IDMedico'))

    axios.post('/dashboards/medicos/home/', formData).then(res => {
      const { Atendimentos } = res.data

      const months = Object.keys(Atendimentos)
      const values = Object.values(Atendimentos).map(i => parseInt(i))

      setCategories(months)
      setData(values)
    })
  }, [])

  return (
    <Container>
      <S.Header>
        <strong>Comparativo Mensal de Atendimentos</strong>
        <D.Select
          data={types}
          onChange={e => {
            setGraphType(e)
          }}
        />
      </S.Header>

      <D.Bars
        data={[{ name: 'Atendimentos', data }]}
        categories={categories}
        type={graphType}
      />
    </Container>
  )
}

export default ComparativoMensal
