import React, { useEffect, useState, useRef } from 'react'
import { useElementScroll } from 'framer-motion'
import { Accordion, AccordionCollapse, Card } from 'react-bootstrap'

import { tabelaCBHPM } from '../../functions/network'
import Collapse from './collapse'
import * as S from './styles'
import { Provider } from './store/convenio'

const CBHPM = () => {
  const [data, setData] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [canLoad, setCanLoad] = useState(true)
  const [location, setLocation] = useState(0.0)
  const [load, setLoad] = useState(-1)

  const divRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress } = useElementScroll(divRef)

  scrollYProgress.onChange(setLocation)

  useEffect(() => {
    getData()
  }, [canLoad])

  useEffect(() => {
    if (!canLoad) {
      if (location > 0.7) {
        setCanLoad(true)
        getData()
      } else {
        setCanLoad(false)
      }
    } else {
      if (location < 0.64 && canLoad) {
        setCanLoad(false)
      }
    }
  }, [location])

  async function getData() {
    if (!canLoad) {
      return
    }

    await tabelaCBHPM.getPerPage(page).then(e => {
      setData([...data, ...e.data])
    })

    setPage(c => c + 1)
    setCanLoad(false)
  }

  return (
    <div
      style={{
        padding: 50,
        overflow: 'scroll',
        marginTop: 50,
        height: '90vh',
        width: '100%'
      }}
      ref={divRef}
    >
      <Provider>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="-1">
              <S.ListStyle>
                <li>Descrição</li>
                <li>Cod CBHPM</li>
              </S.ListStyle>
            </Accordion.Toggle>
          </Card>
          {data.map((i: any | any[], j) => (
            <Card key={j}>
              <Accordion.Toggle
                onClick={() => {
                  setLoad(j)
                }}
                as={Card.Header}
                eventKey={`${j}`}
              >
                <S.ListStyle>
                  <li>{(i?.DescricaoAMB || '').toString()}</li>
                  <li>{i?.CodigoCBHPM}</li>
                </S.ListStyle>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={`${j}`}>
                <Collapse data={i} load={j === load} />
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Provider>
    </div>
  )
}

export default CBHPM
