import React, { useState, useEffect } from 'react'
import ApexCharts from 'react-apexcharts'

const Bars = ({ data, categories, type }) => {
  const [options, setOptions] = useState({})

  useEffect(() => {
    setOptions({
      chart: {
        type,
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories
      }
    })
  }, [categories, type])

  return <ApexCharts options={options} type={type} series={data} />
}

export default Bars
