import React from 'react'

import Routes from './routes'
import Header from './components/header'
import Footer from './components/footer'

function App() {
  return (
    <Routes>
      <Header />
      <Footer />
    </Routes>
  )
}

export default App
