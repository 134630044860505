import styled, { css } from 'styled-components'
import { Container } from 'react-bootstrap'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { VscLoading } from 'react-icons/vsc'

const showCard = css`
  position: relative;
  display: block;
`

const hideCard = css`
  position: absolute;
  top: -300%;
`

const alignLoadingComponent = css`
  display: flex;
  justify-content: center;
  align: center;
  text-align: center;
  width: 100%;
`

export const MainContainer = styled(Container)`
  overflow: hidden !important;
  position: relative;
  transition: 0.3s ease-out;
  background-color: var(--lighter-gray);
  padding: 0;
  margin: 0;
`

export const DetailsInfo = styled.div`
  transition: 1s;
  background-color: #fff;
  width: 100%;
  padding: 5px 12px;

  ${props => (props.isVisible ? showCard : hideCard)};
  ${props => props.isLoading && alignLoadingComponent};
`

export const TitleComponent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 5px;
  font-weight: 500;

  svg {
    margin-right: 12px;
    margin-left: 12px;
  }
`

export const ShowButton = styled.button`
  border: none !important;
  outline: none !important;
  background-color: transparent;
`

export const ArrowComponent = styled(MdKeyboardArrowDown)`
  transition: 0.3s ease-out !important;

  transform: rotate(${props => (props.isVisible ? 0 : 180)}deg);
`

export const LoadingComponent = styled(VscLoading)`
  animation: rotateAnimate 1s infinite linear;
  align-self: center;
  font-size: 18px;
  margin: 10px;

  @keyframes rotateAnimate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
