import axios  from 'utils/axiosAPI'
import { MasterClass } from './master-class'
import { IFilaImagem } from 'functions/@types/fila-de-imagens'


class FilaDeImagens extends MasterClass {

  async get(IDProducao: number | string){
    let img: IFilaImagem[] = [];
    return await axios.get(`producao/${IDProducao}/guia`)
      .then(e => {
        img = e.data.data;
        return img;
      })
      .catch(err => {
        throw new Error(err);
      });
  }
}

export default new FilaDeImagens;