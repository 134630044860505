import React, { useState, useEffect, useCallback } from 'react'
import { Card, Container } from 'react-bootstrap'

import * as C from '../graficos/components'
import { useFetch } from 'hooks'
import * as S from '../graficos/styles'
import { today } from 'pages/graficos/functions'
import { Chart, Body } from './components'
import { DateSeperatorComponent } from 'pages/graficos/components/date-separator/styles'

/**
 *
 * @desc Nova View para subsitituir o antigo src/pages/graficos, em relação ao
 * antigo, ele faz as buscas e armazena-as em cache, sendo assim mais rápido,
 * buscando pequenas informações
 */
const NovosGraficos = () => {
  /**
   *
   * @desc string que contém a url que irá montar os gráficos e as informações
   * de cada paciente
   */
  const [url, setURL] = useState<string>('app')

  /**
   *
   * @desc data inicial e data final da busca dos procedimentos
   */
  const [initialDate, setInitialDate] = useState<string | undefined>()
  const [finalDate, setFinalDate] = useState<string | undefined>()
  const [sortFilter, setSortFilter] = useState(0)

  /**
   *
   * @desc diz se vai ser em quantidade ou valor, e se será convênios ou hospitais
   */
  const [filter, setFilter] = useState({
    monetary: '',
    type: ''
  })

  /**
   * @desc informa quais são as informações e se está as carregando
   */
  const [data, isLoading] = useFetch(url)
  const types = ['atendimentos', 'a-receber', 'recebimentos', 'repasse']

  useEffect(() => {
    const urlParam = new URLSearchParams(window.location.href)

    if (!urlParam || !urlParam.has('filter')) {
      return
    }

    types.map((i, j) => {
      if (urlParam.get('filter') === i) {
        setSortFilter(j)
      }
    })
  }, [])

  useEffect(() => {
    let type = ''

    switch (sortFilter) {
      case 0:
        type = 'atendimentos'
        break

      case 1:
        type = 'a-receber'
        break

      case 2:
        type = 'recebimentos'
        break

      case 3:
        type = 'repasse'
        break

      default:
        type = 'atendimentos'
        break
    }

    const selectBy = filter.type === 'Convênio' ? 'convenio' : 'hospital'

    const _url = `app/graphs?type=${type}&data=['${initialDate || today}'-'${
      finalDate || today
    }']&selectBy=${selectBy}`
    setURL(_url)
  }, [initialDate, finalDate, sortFilter, filter])

  useEffect(() => {
    console.log({ url })
  }, [url])

  /**
   * @desc conta quantos atendimentos tiveram ao total
   */
  function countObjects() {
    let row = 0

    if (!data || data?.data.producao) {
      return row
    }

    data?.data.map(i => {
      row += i.count
    })

    return row
  }

  useEffect(() => {
    console.log({ data })
  }, [data])

  return (
    <div style={{ marginTop: '80px', marginBottom: '180px' }}>
      <Container>
        <C.DropdownDate initialDate={setInitialDate} finalDate={setFinalDate} />

        <C.Loading loading={isLoading} />

        <S.GraphicContainer>
          <S.GraphicDetailsWrapper>
            <C.SortFilters {...{ sortFilter, setSortFilter }} />
            <div>
              {countObjects() > 0 && (
                <span>
                  {countObjects()} Atendimento
                  {countObjects() > 1 ? 's' : ''}
                </span>
              )}
            </div>
          </S.GraphicDetailsWrapper>
          <Chart dataSource={data?.dashboard || [{}]} />
        </S.GraphicContainer>

        <C.Filters updateFilters={setFilter} />

        {data?.data && (
          <>
            <S.TextWrapper>
              {data.data?.consolidado
                ? Object.keys(data.data?.consolidado || []).length > 0
                  ? 'Resumo'
                  : 'Sem Dados'
                : Object.keys(data.data).length > 0
                ? 'Resumo'
                : 'Sem Dados'}
            </S.TextWrapper>

            <DateSeperatorComponent>
              {data.data.producao
                ? data.data.producao.map((i, j) => (
                    <Card key={j}>
                      <Body evk={j} {...i} />
                    </Card>
                  ))
                : data.data
                    .sort((a: any, b: any) => {
                      const e = new Date(b.date)
                      const d = new Date(a.date)

                      return e > d ? 1 : -1
                    })
                    .map((i, j) => (
                      <Card key={j}>
                        <Body evk={j} {...i} />
                      </Card>
                    ))}
            </DateSeperatorComponent>
          </>
        )}
      </Container>
    </div>
  )
}

export default NovosGraficos
