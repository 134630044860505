import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'

import { dates } from '../../functions'

const SELECT_BETWEEN_DATES = '7'
const SELECT_DATE_TEXT = 'Selecionar Periodo'

const DropdownDate = ({ initialDate, finalDate }) => {
  const [fromWhen, setFromWhen] = useState('')
  const [selectedDate, setSelectedDate] = useState(0)
  const [show, setShow] = useState(false)
  const [select, setSelect] = useState()
  const [selectText, setSelectText] = useState(SELECT_DATE_TEXT)

  const initialDateRef = useRef(null)
  const finalDateRef = useRef(null)
  const optionRef = useRef(null)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.href)

    if (searchParam.get('params') === '1') {
      if (selectedDate === 4) {
        return
      }
      setSelectedDate(4)
      // setFromWhen(dates[4].name)
      // initialDate(dates[4].initialDate)
      // finalDate(dates[4].finalDate)
    }
  }, [])

  useEffect(() => {
    const url = new URLSearchParams(window.location.href)
    const has = url.has('data')

    if (!has) {
      return
    }

    const val = url.get('data')
    // const values = dates.find(({ name }) => name.toLowerCase() === val);

    let values = null

    switch (val) {
      case 'hoje':
        values = dates[0]
        break

      case 'ontem':
        values = dates[2]
        break

      case 'mes-atual':
        values = dates[4]
        break
    }

    if (!values) {
      return
    }

    setSelect(values.value)
  }, [])

  useEffect(() => {
    setSelectText(SELECT_DATE_TEXT)

    if (selectedDate !== SELECT_BETWEEN_DATES) {
      setFromWhen(dates[selectedDate].name)
      initialDate(dates[selectedDate].initialDate)
      finalDate(dates[selectedDate].finalDate)
    } else {
      handleShow()
    }
  }, [finalDate, initialDate, selectedDate])

  function setNewRefDate() {
    const i = initialDateRef.current.value
    const f = finalDateRef.current.value

    const _i = i.split('-')
    const _f = f.split('-')

    const date = `${[_i[2], _i[1], _i[0]].join('/')} ${[
      _f[2],
      _f[1],
      _f[0]
    ].join('/')}`
    setFromWhen(date)
    setSelectText(date)

    initialDate(i)
    finalDate(f)

    handleClose()
  }

  function handleChange(e) {
    setSelectedDate(e.target.value)
  }

  return (
    <>
      <select
        onChange={handleChange}
        defaultValue={select}
        className="btn btn-primary"
        style={{ backgroundColor: 'var(--blue)', border: 'none' }}
      >
        {dates.map((i, j) => (
          <option key={j} selected={j === select} value={i.value}>
            {i.name}
          </option>
        ))}
        <option value={SELECT_BETWEEN_DATES} onClick={handleShow}>
          {selectText}
        </option>
      </select>
      <Modal {...{ show }} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Selecionar Período</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>Data Inicial:</Col>
              <Col>
                <input
                  type="date"
                  className="form-control"
                  ref={initialDateRef}
                />
              </Col>
            </Row>
            <Row>
              <Col>Data Final:</Col>
              <Col>
                <input
                  type="date"
                  className="form-control"
                  ref={finalDateRef}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Fechar
          </Button>
          <Button onClick={setNewRefDate}>Gerar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

DropdownDate.propTypes = {
  initialDate: PropTypes.func,
  finalDate: PropTypes.func
}

export default DropdownDate
