import React, { useState, useRef, useEffect } from 'react'
import { IoIosClose } from 'react-icons/io'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { Form } from 'react-bootstrap'

import { Switch } from '../../../../components/switch'
import * as S from './styles'
import { NotaContext, InterfaceFile } from './context'

interface DeducoesProps {
  DataFim: string
  DataInicio: string
  ID: number
  IDRepasse: number
}

interface InterfaceDate {
  Competencia: string
  DataRepasse: string
  Deducoes: DeducoesProps[]
  ID: number
  IDCliente: number
  IDMedico: number
  Medico: IMedico
}

interface ListaProps {
  data?: IRepasse[] | null
  setButton: any
  setNotas: any
}

export function Lista({ data, setButton, setNotas }: ListaProps) {
  const [isXML, setIsXML] = useState(true)
  const [files, setFiles] = useState<InterfaceFile[]>([])
  const [active, setActive] = useState(false)

  useEffect(() => {
    setButton(active)
  }, [active])

  useEffect(() => {
    if (!files.length) {
      return
    }
    setNotas(files)
  }, [files])

  // function sortArrayDate(array: ListaProps[]) {
  // 	return array.sort((a, b) => (
  // 		new Date((a.data.toString().split('-')) as string) - new Date(b.data.toString().split('-'))
  // 	)).map((i, j) => (

  // 	))
  // }

  return (
    <NotaContext.Provider
      value={{ active, setActive, isXML, setIsXML, files, setFiles }}
    >
      <S.NFList>
        <Form.Group>
          <S.NF>
            <div className="row">
              <div className="col-5 ab">Tipo de Arquivo</div>
              <div className="col-5">
                <Switch changed={setIsXML} />
              </div>
            </div>
          </S.NF>
        </Form.Group>
        <AnimateSharedLayout>
          <motion.ul layout initial={{ borderRadius: 25 }}>
            {data
              ?.sort(
                (a, b) =>
                  new Date(`${a.DataRepasse}`).getTime() -
                  new Date(`${b.DataRepasse}`).getTime()
              )
              .map((i, j) => (
                <Item key={j} content={i} val={j} {...{ setActive }} />
              ))}
          </motion.ul>
        </AnimateSharedLayout>
      </S.NFList>
    </NotaContext.Provider>
  )
}

interface InterfaceItem {
  content: any
  val: number
}

function Item({ content, val }: InterfaceItem) {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  const [filename, setName] = useState('')
  const [remNF, isName] = useState(true)
  const handleNF = () => isName(!remNF)
  const allowedExtensionXML = '.xml'
  const allowedExtensionOutros = '.xml, .jpg, .jpeg, .pdf, .png'

  const inputFileRef = useRef<HTMLInputElement>(null)

  const { active, setActive, isXML, setFiles, files } = React.useContext(
    NotaContext
  )

  function nomeNota(nome: string) {
    const name = nome.slice(0, 12)
    setName(name + '...')
    handleNF()
  }
  function convertToDate(date: string) {
    const day = date.split('-')[2]
    const month = date.split('-')[1]
    const year = date.split('-')[0]

    return `${day}/${month}/${year}`
  }
  function removeNota() {
    setFiles(files.filter(i => i.index !== val))
    handleNF()
    setName('')
    toggleOpen()
  }

  return (
    <motion.li layout initial={{ borderRadius: 10 }}>
      <div>
        <motion.h5 layout onClick={toggleOpen}>
          {
            <div style={{ display: 'flex' }}>
              <div className="pergunta">
                Data: {content.DataRepasse} Valor: R$ {content.ValorRepassado}
              </div>
            </div>
          }
        </motion.h5>
        <AnimatePresence>
          {isOpen && (
            <S.NFList>
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="resposta">
                  <S.NF>
                    <Form.Group>
                      <div className="row">
                        <div className="col-7">
                          <button
                            onClick={() => {
                              inputFileRef.current?.click()
                            }}
                          >
                            Selecione o Arquivo
                          </button>
                          <input
                            type="file"
                            id="fileinput"
                            accept={
                              isXML
                                ? 'text/xml'
                                : '.xml, .jpg, .jpeg, .pdf, .png'
                            }
                            hidden
                            capture="filesystem"
                            ref={inputFileRef}
                            onChange={e => {
                              if (e.target.files) {
                                if (inputFileRef.current?.files) {
                                  // if (!isXML && !(inputFileRef.current?.files[0].name.endsWith(allowedExtensionOutros))){
                                  //   setName("")
                                  //   setActive(false)
                                  //   alert("Tipo de arquivo não suportado!")
                                  //   return
                                  // }
                                  if (
                                    isXML &&
                                    !inputFileRef.current.files[0].name.endsWith(
                                      allowedExtensionXML
                                    )
                                  ) {
                                    setName('')
                                    setActive(false)
                                    alert('Tipo de arquivo não suportado!')
                                    return
                                  } else {
                                    setActive(true)
                                  }
                                  setFiles(files.filter(i => i.index !== val))

                                  const newData: InterfaceFile = {
                                    index: val,
                                    file: inputFileRef.current
                                      ?.files[0] as File,
                                    repasse: content
                                  }

                                  setFiles(files => [...files, newData])
                                } else {
                                  setFiles(files.filter(i => i.index !== val))
                                }
                                nomeNota(e.target.files[0].name)
                              }
                            }}
                          />
                        </div>
                        <div className="col-5 nome">
                          {filename}
                          <IoIosClose
                            className="icon"
                            style={{ display: remNF ? 'none' : 'block' }}
                            onClick={removeNota}
                            title="Remover"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </S.NF>
                </div>
              </motion.div>
            </S.NFList>
          )}
        </AnimatePresence>
      </div>
    </motion.li>
  )
}

export default Lista
