import React from 'react'
import { Accordion, Card } from 'react-bootstrap'

import { convertToMoney } from 'functions'
import {
  AccordionComponent,
  PatientDetails,
  PatientInfo
} from 'pages/graficos/components/date-separator/styles'

import RepasseProducao from './repasse-producao'
import TipoDeducao from './tipo-deducao'

const Repasse = ({ data }: { data: IRepasse }) => {
  return (
    <div>
      <Accordion.Toggle as={AccordionComponent} eventKey={`rp-${data?.ID}`}>
        <span>{(data?.DataRepasse || '').split('-').reverse().join('/')}</span>

        <strong style={{ color: 'var(--blue)' }}>Valor Consolidado</strong>

        <span style={{ color: '#44c767' }}>
          {convertToMoney(data.ValorRepassado)}
        </span>
      </Accordion.Toggle>
      <PatientInfo eventKey={`rp-${data?.ID}`}>
        <PatientDetails style={{ margin: 0, padding: 0 }}>
          <p>
            <strong>Valor Repasse</strong>
            <span>{convertToMoney(data.ValorRepassado)}</span>
          </p>

          <p>
            <strong>Total Bruto</strong>
            <span>{convertToMoney(data.TotalBrutoNF)}</span>
          </p>

          <Card>
            <Card.Header>Adições e Descontos</Card.Header>
            <Card.Body>
              {Object.keys(data.Deducoes).length > 0 && (
                <TipoDeducao data={data.Deducoes} />
              )}
            </Card.Body>
          </Card>
        </PatientDetails>
      </PatientInfo>
      <Card>
        <RepasseProducao ID={data.ID} />
      </Card>
    </div>
  )
}

export default Repasse
