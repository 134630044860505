import { useState } from 'react'
import useSWR from 'swr'

import api from 'utils/axiosAPI'

export function useFetch<Data = any, Error = any>(url: string) {
  const [isLoading, setIsLoading] = useState(false)

  const { data, error } = useSWR(url as string, async url => {
    setIsLoading(true)

    const response = await api.get(url)

    setIsLoading(false)

    return response.data
  })

  return [data, error, isLoading]
}
