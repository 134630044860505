import { getData } from '../get-data'
import { MasterClass } from './master-class'

class CondicoesDePagamento extends MasterClass {
  async get(): Promise<Condicoes[]> {
    const url = `/condicao_pagamento`
    let condicoes: Condicoes[] = []

    await getData(url)
      .then(res => {
        condicoes = res as Condicoes[]
      })
      .catch(err => {
        console.log({ err })
      })

    return condicoes
  }

  async update() {
    //
  }

  async store() {
    //
  }

  async delete() {
    //
  }
}

export default new CondicoesDePagamento()
