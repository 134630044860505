import { MasterClass } from './master-class'
import { getData } from '../get-data'

class Configuracao extends MasterClass implements InterfaceClassConfiguracao {
  async get() {
    let values: InterfaceConfiguracao[] = []

    await getData('/configuracao').then(res => {
      values = res
    })

    return values
  }

  async update() {
    //
  }

  async store() {
    //
  }

  async delete() {
    //
  }
}

export default new Configuracao()
