import styled from 'styled-components'
import { VscLoading } from 'react-icons/vsc'

export const LoadingComponent = styled(VscLoading)`
  animation: rotateAnimate 1s infinite linear;
  align-self: center;
  font-size: 18px;
  margin: 10px;

  @keyframes rotateAnimate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const ExtratoComponent = styled.div`
  .extrato {
    margin-top: 10px;

    .title {
      margin-left: 40px;
    }
    .money {
      color: #44c767;
      text-align: left;
      margin-left: 30px;
    }
    .debit {
      color: #dc3545;
      text-align: left;
      margin-left: 30px;
    }
    .total {
      color: #387fba;
      text-align: left;
      margin-left: 31px;
    }
    .repasse {
      font-weight: bolder;
      margin-left: 31px;
    }
    a {
      margin-left: 40px;
      margin-bottom: 10px;
    }
  }
`
