import React, { useState, useEffect } from 'react'
import { Accordion, Card, Col, Row } from 'react-bootstrap'
import { BiTransferAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'

import * as S from './styles'
import { convertToDate, getAboveTen, today } from 'pages/graficos/functions'
import axios from 'utils/axiosAPI'
import convertToMoney from 'functions/convert-to-money'

const ExtratoMedico = () => {
  const [data, setData] = useState<Repasse[]>([])
  const [isVisible, setIsVisible] = useState(false)
  const handdleIsVisible = () => setIsVisible(!isVisible)
  const [isLoading, setIsLoading] = useState(true)
  const lastday = function (y, m) {
    return new Date(y, m, 0).getDate()
  }
  const _today = convertToDate(today).split('-')
  const year = _today[0]
  const month = _today[1]
  const day = getAboveTen(new Date().getDate())
  const [repasse, setRepasse] = useState(0.0)
  const [total, setTotal] = useState(0.0)
  const [add, setAdd] = useState(0.0)
  const [sub, setSub] = useState(0.0)
  const [imp, setImp] = useState(0.0)

  useEffect(() => {
    if (!data) {
      return
    }
    calculaExtrato()
  }, [data])

  useEffect(() => {
    if (!isVisible) {
      return
    }
    getRepasse()
  }, [isVisible])

  async function getRepasse() {
    setIsLoading(true)

    await axios
      .get(
        `/repasse?perPage=100&DataRepasseInicial=${year}-${month}-01&DataRepasseFinal=${year}-${month}-${day}`
      )
      .then(res => {
        setData(res.data.data)
      })
      .catch(console.warn)

    setIsLoading(false)
  }

  function sumValues(_data_: Repasse, type: 0 | 1 | 2): number {
    const value = _data_.Deducoes.filter(i => i.Modo === type).reduce(
      (i, j) => i + j.Valor,
      0
    )
    // const sum = value.reduce((i, j) => { i + (j?.Valor || 0)}, 0)

    return value
  }

  function calculaExtrato() {
    let _repasse = 0.0
    let _total = 0.0
    let _add = 0.0
    let _sub = 0.0
    let _imp = 0.0

    data.map((i, j) => {
      _imp += sumValues(i, 0)
      _add += sumValues(i, 1)
      _sub += sumValues(i, 2)

      _total += parseFloat(i.TotalBrutoNF)
      _repasse += parseFloat(i.ValorRepassado)
    })

    /*
    for (let i = 0; i < data.length; i++) {
      if(data[i].Deducoes || data[i].Deducoes[0].Modo === null){
        _total += parseFloat(data[i].TotalBrutoNF)
        if(data[i].ValorRepassado === null){
          continue
        }else{
          _repasse += parseFloat(data[i].ValorRepassado)
        }
        continue
      }else {
        for (let j = 0; j < data[i].Deducoes.length; j++) {
          if(data[i].Deducoes[j].Modo === 0){
            _sub += data[i].Deducoes[j].Valor
          }else if(data[i].Deducoes[j].Modo === 1){
            _add += data[i].Deducoes[j].Valor
          }else if(data[i].Deducoes[j].Modo === 2){
            _imp += data[i].Deducoes[j].Valor
          } 
        }
      }
      _total += parseFloat(data[i].TotalBrutoNF)
      _repasse += parseFloat(data[i].ValorRepassado)
    }
    */

    setRepasse(_repasse)
    setTotal(_total)
    setAdd(_add)
    setSub(_sub)
    setImp(_imp)
  }

  return (
    <S.ExtratoComponent>
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            onClick={handdleIsVisible}
            eventKey={'0'}
          >
            <span>
              <BiTransferAlt /> Extrato Mês Atual
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={'0'}>
            {isLoading ? (
              <>
                <S.LoadingComponent />
                Carregando
              </>
            ) : data ? (
              <div className="extrato">
                <Row>
                  <Col>
                    <div className="title">Total Bruto:</div>
                  </Col>
                  <Col>
                    <div className="total">{convertToMoney(total)}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="title">Total Retenções:</div>
                  </Col>
                  <Col>
                    <div className="debit">-{convertToMoney(imp)}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="title">Total Descontos:</div>
                  </Col>
                  <Col>
                    <div className="debit">-{convertToMoney(sub)}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="title">Total Adicionais:</div>
                  </Col>
                  <Col>
                    <div className="money">+{convertToMoney(add)}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="title">Total Repasse:</div>
                  </Col>
                  <Col>
                    <div className="repasse">{convertToMoney(repasse)}</div>
                  </Col>
                </Row>
                <Link to="/graficos?&params=1&monetary=R$&type=Convênio&filter=repasse&data=mes-atual">
                  Detalhar
                </Link>
              </div>
            ) : (
              <div>Sem Dados</div>
            )}
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </S.ExtratoComponent>
  )
}

export default ExtratoMedico
