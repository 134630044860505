export default function saveData(data) {
  try {
    const userJSON = JSON.parse(atob(data.AccessToken.split('.')[1]))

    if (parseInt(userJSON.user.IDMedico) === 0) {
      alert('Esse usuário não é médico!')
      return
    }

    localStorage.setItem('@webmed-Token', data.AccessToken)
    localStorage.setItem('IDCliente', userJSON.user.IDCliente)
    localStorage.setItem('IDUsuario', userJSON.user.ID)
    localStorage.setItem('userWebmed', userJSON.user.Usuario)
    localStorage.setItem('IDMedico', userJSON.user.IDMedico)

    return true
  } catch (err) {
    return false
  }
}
