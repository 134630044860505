import React from 'react'

import { convertToMoney } from 'functions'

const TipoDeducao = ({ data }: { data: IDeducoes[] }) => {
  function filterData(arr: IDeducoes[], tipo: number) {
    return arr.filter(a => {
      if (tipo === a.Modo) {
        return {
          Nome: a.Nome,
          Valor: a.Valor
        }
      }
    })
  }

  function GetView({ Nome, Valor }: { Nome: string; Valor: string }) {
    return (
      <p>
        <strong>{Nome}</strong>
        <span>{convertToMoney(Valor)}</span>
      </p>
    )
  }

  return (
    <div>
      {filterData(data, 0).map((a, b) => (
        <div>
          Descontos
          <GetView key={b} {...a} />
        </div>
      ))}

      {filterData(data, 1).map((a, b) => (
        <div>
          Adições
          <GetView key={b} {...a} />
        </div>
      ))}

      {filterData(data, 2).map((a, b) => (
        <div>
          Retenções NF
          <GetView key={b} {...a} />
        </div>
      ))}
    </div>
  )
}

export default TipoDeducao
