/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import Chart, {
  Legend,
  Label,
  Font,
  ArgumentAxis,
  Series,
  // ZoomAndPan,
  ScrollBar,
  Tooltip,
  Size,
  ValueAxis,
  CommonSeriesSettings
} from 'devextreme-react/chart'
import { Modal, Card, Container } from 'react-bootstrap'

import { convertToMoney } from 'functions'

const GChart = ({ dataSource }: { dataSource: any[] }) => {
  const [data, setData] = useState<any | any[]>()
  const [show, setShow] = useState(false)

  const onHide = () => setShow(false)
  const handleShow = () => setShow(true)

  const dateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/

  useEffect(() => {
    console.log({ data })
  }, [data])

  useEffect(() => {
    if (Object.keys(dataSource).length <= 0) {
      return
    }

    dataSource = dataSource.map(i => {
      if (!i.name) {
        return i
      }

      if (!i.name.match(dateRegex)) {
        if (i.sigla) {
          return i
        }

        let tmpString = ''
        const name = (i.name as string).split(' ')

        name.map((i, j) => {
          tmpString += i.substr(0, 3)

          if (j !== name.length && (i.length || 0) > 3) {
            tmpString += '. '
          }
        })

        i.sigla = tmpString
      } else {
        i.sigla = i.name.split('-').reverse().splice(0, 2).join('/')
      }

      return i
    })

    const max = Math.max(...dataSource.map(i => i.value))

    const more = {
      sigla: 'Ver Mais...',
      monetary_values: max
    }

    const _data = [...dataSource.slice(0, 3)]

    if (Object.keys(dataSource).length > 2) {
      _data.push(more)
    }

    setData(_data)
  }, [dataSource])

  const isMonetary = true
  const plus = 'Ver Mais...'

  function customizeTooltip(arg) {
    let valorMostrar = arg.value
    if (isMonetary) {
      valorMostrar = convertToMoney(valorMostrar)
      return {
        text: `${valorMostrar}`
      }
    } else {
      if (arg.argument !== plus) {
        if (valorMostrar === 1) {
          valorMostrar = valorMostrar + ' atendimento'
        } else {
          valorMostrar = valorMostrar + ' atendimentos'
        }

        const argument = dataSource.filter(i => i.name === arg.argument)[0]
          .description

        return {
          text: `${argument} ${valorMostrar}`
        }
      } else {
        return {
          text: 'Aperte aqui e veja outros atendimentos'
        }
      }
    }
  }

  function onPointClick(e) {
    if (e.target.data.sigla === 'Ver Mais...') {
      handleShow()
    } else {
      const { argument } = e.target
      // setLastFilter(lastFilter === argument ? null : argument);
    }
  }

  return (
    <>
      <Chart dataSource={data} {...{ onPointClick }}>
        <CommonSeriesSettings argumentField="sigla" type="bar" />

        <Series valueField="monetary_values" color={'#90d7da'} type="bar" />

        <Legend visible={false} />
        <ArgumentAxis valueMarginsEnabled />

        <Tooltip enabled={true} location={'edge'} {...{ customizeTooltip }} />
        <ScrollBar visible={false} />
        <Size height={190} />

        <ValueAxis visible={false}>
          <Label visible={false} />
        </ValueAxis>

        <ValueAxis visible={false} />
      </Chart>

      <Modal {...{ show, onHide }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ver Mais...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ overflowY: 'scroll' }}>
            {dataSource?.map((i, j) => (
              <Card key={j} style={{ marginTop: 10 }}>
                <Card.Body>
                  <p>
                    <strong>
                      {(i?.name || '').match(dateRegex) ? 'Data' : 'Sigla'}:
                    </strong>{' '}
                    {i.name}
                  </p>
                  <p>
                    <strong>Valor:</strong> {convertToMoney(i.monetary_values)}
                  </p>
                  {/*
                  <br />
                  Nome: {i.description}
                  <br />
                  {isMonetary ? `Valor: ${convertToMoney(i.value)}` : `Atendimentos: ${i.value}` }
                  */}
                </Card.Body>
              </Card>
            ))}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GChart
