import { AxiosResponse } from 'axios'

import { MasterClass } from './master-class'
import { getData } from '../get-data'
import axios from '../../utils/axiosAPI'

class Usuarios extends MasterClass implements InterfaceUsuario {
  async get(): Promise<IUsuario[]> {
    let Usuarios: IUsuario[] = []

    await getData(this.getURL('usuario'))
      .then(res => {
        Usuarios = res as IUsuario[]
      })
      .catch(err => {
        console.error({ err })
      })

    return Usuarios
  }

  async update({ ID, Ativo, Email, IDCategoria, IDCliente, Usuario, Senha }) {
    await axios({
      url: `/usuario/${ID}`,
      data: {
        Ativo,
        Email,
        IDCategoria,
        IDCliente,
        Usuario,
        Senha
      },
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res: AxiosResponse<IUsuario>) => {
      console.log({ res })
    })

    return null
  }
}

export default new Usuarios()
