import React, { useRef, useEffect, useState } from 'react'
import { FaCamera } from 'react-icons/fa'
import { IoIosClose } from 'react-icons/io'
import { FormGroup, Image, Button, ProgressBar, Alert } from 'react-bootstrap'

import * as S from '../styles'
import axios from 'utils/axiosAPI'
import {
  ModalComponent,
  ModalComponentBody,
  ModalTitleComponent
} from './styles'

const Camera = () => {
  const inputRef = useRef(null)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setProgress(0)
    setImgPreview()
    setHideImg(true)
    showAlert(false)
    setShow(false)
  }
  const [progress, setProgress] = useState(0)
  const [file, setFile] = useState()
  const [imgPreview, setImgPreview] = useState()
  const [hideProgress, setHideProgress] = useState(true)
  const [hideImg, setHideImg] = useState(true)
  const [alert, showAlert] = useState(false)

  function onHide() {
    setShow(false)
  }

  function close() {
    showAlert(false)
    setShow(false)
  }

  function onClick() {
    handleShow()
  }

  function upload(evento) {
    const mod = document.getElementById('modal')
    mod.scrollIntoView(true)
    evento.preventDefault()
    sendFile(file, e => {
      setProgress(Math.round((100 * e.loaded) / e.total))
    })
      .then(res => {
        setFile()
        setProgress(0)
        setImgPreview()
        showAlert(true)
      })
      .catch(console.warn)
  }

  useEffect(() => {
    if (progress != 0) {
      setHideProgress(false)
    } else {
      setHideProgress(true)
    }
  }, [progress])

  useEffect(() => {
    if (typeof file === 'undefined') {
      if (!inputRef.current) {
        return
      }
      inputRef.current.value = null
      setHideImg(true)
      return
    }
    setHideImg(false)
    setImgPreview(URL.createObjectURL(file))
  }, [file])

  async function sendFile(image, onUploadProgress) {
    const data = new FormData()
    data.append('Guia', image)

    console.log({ image })

    await axios.post('/fila_imagens', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  return (
    <div>
      <S.ButtonComponent {...{ onClick }} style={{ paddingBottom: '10px' }}>
        <FaCamera />
      </S.ButtonComponent>
      <ModalComponent {...{ show, onHide }} id="modal" centered>
        <Alert
          variant="success"
          show={alert}
          onClose={() => close()}
          dismissible
        >
          <Alert.Heading>Imagem enviada com sucesso!</Alert.Heading>
          <p>
            A imagem se encontra na Fila para realizar o cadastro do
            atendimento!
          </p>
        </Alert>
        <ModalComponent.Header closeButton>
          <ModalTitleComponent>Enviar Imagem</ModalTitleComponent>
        </ModalComponent.Header>
        <ProgressBar
          hidden={hideProgress}
          now={progress}
          label={`${progress}%`}
        />
        <ModalComponentBody>
          <div hidden={hideImg} style={{ position: 'relative' }}>
            <IoIosClose
              style={{
                right: 0,
                position: 'absolute',
                color: 'red',
                fontSize: 30
              }}
              onClick={() => setFile()}
            />
            <Image src={imgPreview} thumbnail />
          </div>
          <FormGroup>
            <button
              onClick={() => {
                inputRef.current.click()
              }}
            >
              Selecione o Arquivo
            </button>
            <input
              type="file"
              id="imageinput"
              hidden
              accept="image/*"
              ref={inputRef}
              onChange={e => {
                console.log(e.target.files)
                if (e.target.files) {
                  setFile(e.target.files[0])
                }
              }}
            />
          </FormGroup>
        </ModalComponentBody>
        <ModalComponent.Footer>
          <Button variant="light" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={e => upload(e)}>
            Enviar
          </Button>
        </ModalComponent.Footer>
      </ModalComponent>
    </div>
  )
}

export default Camera
