import { MasterClass } from './master-class'
import { getData } from '../get-data'

import axios from 'utils/axiosAPI'

class TabelaCBHPM extends MasterClass {
  public async get() {
    return await getData('tabelacbhpm?perPage=1500')
      .then((res: any | any[]) => {
        return res
      })
      .catch(() => {
        return []
      })
  }

  public async getPerPage(nPage?: number) {
    let params: string

    if (nPage && nPage >= 1) {
      params = `?page=${nPage}`
    } else {
      params = ''
    }

    // params += params.includes('?') ? '&' : '?'
    // params += 'perPage=1500'

    return await axios
      .get(`tabelacbhpm${params}`)
      .then(res => {
        return res.data
      })
      .catch(err => {
        throw Error(JSON.stringify(err))
      })
  }

  public async getPayment(id: number) {
    return await getData(this.getURL(`tabelacbhpm/${id}`))
      .then(res => {
        return res
      })
      .catch(err => {
        throw new Error(err)
      })
  }
}

export default new TabelaCBHPM()
