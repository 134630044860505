import { AxiosResponse } from 'axios'

import { MasterClass } from './master-class'
import { getData } from '../get-data'
import axios from '../../utils/axiosAPI'

class Medico extends MasterClass {
  /**
   * @override
   */
  async get(): Promise<IMedico[]> {
    let medicos: IMedico[] = []

    const url = `/medico?perPage=100${
      this.getIsAdmin() ? `&IDCliente=${this.getIDCliente()}` : ''
    }`

    await getData(url)
      .then(res => {
        medicos = res as IMedico[]
      })
      .catch(err => {
        console.error({ err })
      })

    return medicos
  }

  async update(medico: IMedico): Promise<IMedico | null> {
    let m: IMedico = medico

    const {
      CRM,
      Email,
      Especialidades,
      Subespecialidades,
      Nome,
      Hospitais
    } = medico

    await axios({
      url: `/medico/${medico.ID}`,
      method: 'PUT',
      data: {
        CRM,
        Email,
        Especialidades,
        Subespecialidades,
        Ex: 0,
        Estado: 'RS',
        Nome,
        Hospitais
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        m = res.data
      })
      .catch(err => {
        console.error({ err })
      })

    return m
  }

  async delete(id: number): Promise<AxiosResponse | null> {
    let response: AxiosResponse | null = null

    await axios
      .delete(`/medico/${id}`)
      .then(res => {
        response = res.data
      })
      .catch(err => {
        console.error({ err })
      })

    return response
  }
}

export default new Medico()
