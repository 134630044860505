import React, { useRef } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

import { getMonths } from '../../functions'
import { getAboveTen } from '../../pages/graficos/functions'

const actual_month = getAboveTen(new Date().getMonth() + 1)

const MonthYear = ({ onChange }) => {
  const monthRef = useRef(null)
  const yearRef = useRef(null)

  function onClick() {
    const year = yearRef.current.value
    const month = monthRef.current.value

    onChange &&
      onChange({
        month,
        year
      })
  }

  return (
    <>
      <Row>
        <Col>
          Mês:
          <select className="form-control" ref={monthRef}>
            {getMonths().map((i, j) => {
              const value = getAboveTen(j + 1)
              const selected = value === actual_month

              return (
                <option key={j} {...{ value, selected }}>
                  {i}
                </option>
              )
            })}
          </select>
        </Col>

        <Col>
          Ano:
          <select
            className="form-control"
            ref={yearRef}
            style={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            {Array.from(new Array(5)).map((i, j) => {
              const year = new Date().getFullYear() - j

              return (
                <option value={year} key={j}>
                  {year}
                </option>
              )
            })}
          </select>
        </Col>

        <Col>
          <Button
            variant="success"
            style={{ marginTop: '22px' }}
            {...{ onClick }}
          >
            Buscar
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default MonthYear
