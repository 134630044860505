import React from 'react'
import { Link } from 'react-router-dom'

import { navigationStore } from '../../store'

export default function Footer({ routes }) {
  const { setPathname } = React.useContext(navigationStore)

  return (
    <ul className="footer-menu">
      {routes.map((i, j) => (
        <li key={j}>
          <Link
            className="nav-link"
            to={i.route}
            onClick={e => {
              setPathname(i.route)
            }}
          >
            <i.icon />
          </Link>
        </li>
      ))}
    </ul>
  )
}
