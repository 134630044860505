import React, { useContext } from 'react'
import { Accordion, Card, AccordionContext } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io'

import { convertToMoney } from 'functions'
import * as S from './styles'
import Pacientes from './pacients'

const Result = ({ i, j, data }) => {
  return (
    <Card>
      <ContextAwareToggle eventKey={`${j}`} {...{ data, i }} />
      <Accordion.Collapse eventKey={`${j}`}>
        <S.CardComponent>
          <Pacientes {...{ data }} date={i} />
        </S.CardComponent>
      </Accordion.Collapse>
    </Card>
  )
}

interface ContextProps {
  eventKey: string
  callback?: (eventKey: string) => void
  data: any | any[]
  i: string
}

function ContextAwareToggle({ eventKey, callback, data, i }: ContextProps) {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  function convertDate(date) {
    date = date.split('-')
    return [date[2], date[1], date[0]].join('/')
  }

  function numAtendimentos(date) {
    let score = 0

    score = data.filter(a => a.Data === date)

    return Object.keys(score).length
  }

  function sumValues(date) {
    let val = 0

    data
      .filter(a => a.Data === date)
      .map(i => {
        val += parseFloat(i.ValorOrcado)
      })

    return val
  }

  return (
    <Accordion.Toggle
      as={S.MainHeader}
      eventKey={eventKey}
      onClick={decoratedOnClick}
      style={{ paddingLeft: '10px' }}
    >
      {isCurrentEventKey ? (
        <IoMdArrowDropdown style={{ marginTop: '4px' }} />
      ) : (
        <IoMdArrowDropright style={{ marginTop: '4px' }} />
      )}
      <span>{convertDate(i)}</span>
      <div>{numAtendimentos(i)} Atendimentos</div>
      <span style={{ color: '#44c767' }}>{convertToMoney(sumValues(i))}</span>
    </Accordion.Toggle>
  )
}

export default Result
