import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

import * as S from '../styles'
import * as C from '../../components'
import {
  FAB,
  ModalTitleComponent,
  ModalComponent,
  ModalComponentBody
} from './styles'
import axios from '../../../../utils/axiosAPI'
import { getData } from '../../../../functions'
import { getAboveTen } from '../../functions'
import { Lista } from './lista'
import { InterfaceFile } from './context'

const Nota = () => {
  const [show, setShow] = useState(false)
  const [lista, setLista] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const onHide = () => setShow(false)
  const hideList = () => setLista(false)
  const showList = () => setLista(true)
  const dataRef = useRef<HTMLInputElement>(null)
  const [notas, setNotas] = useState<InterfaceFile[]>([])
  const [button, setButton] = useState(false)
  const [mes, setMes] = useState<string | number>()
  const [ano, setAno] = useState<string | number>()
  const [data, setData] = useState<IRepasse[] | null>()
  const [loading, setLoading] = useState(false)
  const [today, setToday] = useState<string>('')
  const [empty, isEmpty] = useState(false)
  const [bvariant, setVariant] = useState('white')
  const d = new Date()
  const year = d.getFullYear()
  const month = getAboveTen(d.getMonth() + 1)
  const lastday = function (y, m) {
    return new Date(y, m, 0).getDate()
  }

  useEffect(() => {
    if (empty) {
      setVariant('danger')
    } else setVariant('white')
  }, [empty])

  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.length === 0) {
      isEmpty(true)
      showList()
    } else {
      showList()
    }
  }, [data])

  async function getRepasses() {
    setLoading(true)

    const url = `/repasse?PossuiNotaFiscal=false&DataRepasseInicial=${ano}-${mes}-01&DataRepasseFinal=${ano}-${mes}-${lastday(
      ano,
      mes
    )}`
    await getData(url)
      .then(setData)
      .catch(err => {
        alert(err.message)
      })
    setLoading(false)

    handleClose()
  }

  async function postNota(data: InterfaceFile[]) {
    setLoading(true)
    const url = '/repasse/'
    for (let i = 0; i < data.length; i++) {
      const formData = new FormData()
      formData.append('Arquivo', data[i].file)
      await axios
        .post(url + `${data[i].repasse.ID}/nota_fiscal`, formData)
        .then(res => {
          if (res.data.data.ErrosConferencia.length) {
            alert('NF Contém Erros!')
          } else alert('NF Enviada Com Sucesso')
          console.log(res.data.data.ErrosConferencia)
          console.log(res)
        })
        .catch(err => {
          alert(err.message)
        })
    }
    setLoading(false)
    setLista(false)
  }
  function volta() {
    hideList()
    handleShow()
    isEmpty(false)
    setToday([ano, mes].join('-'))
  }

  function abre() {
    handleShow()
    setToday([year, month].join('-'))
    setAno(year)
    setMes(month)
  }

  function onChange(e: React.ChangeEvent) {
    const data = dataRef.current?.value.split('-') as string[]
    const dia = new Date(
      (data[0] as unknown) as number,
      (data[1] as unknown) as number
    )
    const _ano = data[0]
    const _mes = data[1]
    setAno(_ano)
    setMes(_mes)
  }

  return (
    <div>
      <S.ButtonComponent onClick={abre}>
        <FAB style={{ fontFamily: 'Rajdhani-Bold' }}>NF</FAB>
      </S.ButtonComponent>
      <ModalComponent {...{ show, onHide }} centered>
        <ModalComponent.Header closeButton>
          <ModalTitleComponent>Enviar Nota Fiscal</ModalTitleComponent>
        </ModalComponent.Header>
        <ModalComponent.Body>
          <C.Loading {...{ loading }} />
          <Form.Group>
            Competencia:
            <input
              type="month"
              ref={dataRef}
              defaultValue={today}
              className="form-control"
              {...{ onChange }}
            />
          </Form.Group>
        </ModalComponent.Body>
        <ModalComponent.Footer>
          <Button variant="light" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={getRepasses}>
            Buscar
          </Button>
        </ModalComponent.Footer>
      </ModalComponent>

      <ModalComponent show={lista} onHide={hideList} centered>
        <ModalComponent.Header closeButton>
          <ModalTitleComponent>Enviar Nota Fiscal</ModalTitleComponent>
        </ModalComponent.Header>
        <ModalComponentBody>
          <C.Loading {...{ loading }} />
          <div hidden={!empty}>
            Sem dados para emissão de notas fiscais até o momento!
          </div>
          <div hidden={empty}>
            <Lista data={data} {...{ setButton, setNotas }} />
          </div>
        </ModalComponentBody>
        <ModalComponent.Footer>
          <Button variant="white" onClick={volta}>
            Voltar
          </Button>
          <Button variant={bvariant} onClick={hideList}>
            Fechar
          </Button>
          <Button
            variant="primary"
            disabled={!button}
            onClick={() => {
              postNota(notas)
            }}
          >
            Enviar
          </Button>
        </ModalComponent.Footer>
      </ModalComponent>
    </div>
  )
}

export default Nota
