import React, { useState, useEffect, useRef } from 'react'
import { useElementScroll } from 'framer-motion'

import { SearchInterface } from './@types'

const ScrollResult: React.FC<SearchInterface> = ({ children, closeToEnd }) => {
  const [location, setLocation] = useState(0.0)
  const [isClose, setIsClose] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useElementScroll(ref)

  useEffect(() => {
    closeToEnd && closeToEnd(false)
    setIsClose(false)
  }, [])

  scrollYProgress.onChange(setLocation)

  useEffect(() => {
    if (!isClose) {
      if (location > 0.7) {
        setIsClose(true)
        closeToEnd && closeToEnd(true)
      } else {
        setIsClose(false)
      }
    } else {
      if (location < 0.64 && isClose) {
        setIsClose(false)
      }
    }
  }, [location])

  return (
    <div className="row result" ref={ref}>
      {children}
    </div>
  )
}

export default ScrollResult
