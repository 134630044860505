import styled from 'styled-components';

export const ImageComponent = styled.div`

  svg{
    font-size: 25px;
    cursor: pointer;
  }

  img{
    max-width: 80vw;
    max-height: 80vh;
    object-fit: cover;
    overflow: visible;

  }
`