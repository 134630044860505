import React, { useState, useContext } from 'react'
import { Accordion, AccordionContext, Card } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io'

import { convertToMoney } from 'functions'

interface ContextProps {
  eventKey: string
  i: any | any[]
  callback?: (eventKey: string) => void
}
const Result = ({ i, j }) => {
  return (
    <Card>
      <ContextAwareToggle eventKey={`${j}`} {...{ i }} />
      <Accordion.Collapse eventKey={`${j}`}>
        <Card.Body>
          <div className="container">
            <div className="row">
              <div className="col-6 left">Valor Tabela:</div>
              <div className="col-6 right">{convertToMoney(i.ValorOrcado)}</div>
            </div>

            <div className="row">
              <div className="col-6 left">Convênio:</div>
              <div className="col-6 right">{i?.Convenio?.Convenio}</div>
            </div>

            <div className="row">
              <div className="col-6 left">Procedimento:</div>
              <div className="col-6 right">{i.Procedimento?.Sigla}</div>
            </div>

            <div className="row">
              <div className="col-6 left">Status:</div>
              <div className="col-6 right">{i.Status?.Status}</div>
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

function ContextAwareToggle({ eventKey, callback, i }: ContextProps) {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle
      as={Card.Header}
      onClick={decoratedOnClick}
      eventKey={eventKey}
    >
      <div className="row">
        {isCurrentEventKey ? (
          <IoMdArrowDropdown style={{ marginTop: '4px' }} />
        ) : (
          <IoMdArrowDropright style={{ marginTop: '4px' }} />
        )}
        <div className="col-7 left">{i.Paciente}</div>
        <div className="col-4 right">
          {i.Data.split('-').reverse().join('/')}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

export default Result
