import { useState, useEffect } from 'react'

interface UserDataInterface {
  Ativo: boolean
  Email: string
  Foto: string
  ID: number
  IDCategoria: number
  IDCliente: number
  IDMedico: number
  MostrarContrato: number | boolean
  OrientacaoFoto: number
  TokenPass: string
  Usuario: string
}

export default () => {
  const [data, setData] = useState<UserDataInterface>()

  // const infos = ['Ativo', 'Email', 'Foto', 'ID', 'IDCategoria', 'IDCliente', 'IDMedico', 'MostrarContrato', 'OrientacaoFoto', 'TokenPass', 'Usuario']

  useEffect(() => {
    try {
      const token = localStorage.getItem('@webmed-Token') as string
      const base64 = atob(token.split('.')[1])
      const json = JSON.parse(base64)
      const user = json.user

      setData(user)
    } catch (err) {
      setData({} as UserDataInterface)
    }
  }, [])

  if (typeof data !== 'undefined') {
    return data as UserDataInterface
  }
}
