import axios from 'axios'

const sandboxURL = 'https://webmedbr.com/sandbox.phpqueries/'
const serverURL = 'https://webmedbr.com/phpqueries/'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.location.hostname.includes('sandbox')
      ? sandboxURL
      : serverURL
    : sandboxURL

// const baseURL = serverURL;

export default axios.create({
  baseURL
})
