import React, {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction
} from 'react'

import { convenios as Convenios } from '../../../functions/network'

interface StoreConvProps {
  convenios: IConvenio[]
  setConvenios: Dispatch<SetStateAction<IConvenio[]>>
}

export const StoreConvenio = createContext<StoreConvProps>({} as StoreConvProps)

export const Provider: React.FC = ({ children }) => {
  const [convenios, setConvenios] = useState<IConvenio[]>([])

  useEffect(() => {
    Convenios.get().then(setConvenios)
  }, [])

  return (
    <StoreConvenio.Provider value={{ convenios, setConvenios }}>
      {children}
    </StoreConvenio.Provider>
  )
}
