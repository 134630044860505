import React, { ReactNode } from 'react'
import {
  DataGrid,
  Column,
  Summary,
  TotalItem
} from 'devextreme-react/data-grid'
import { isMobile } from 'react-device-detect'

import { convertToMoney, moneyRender } from '../../../functions'

interface InterfaceResumoRepasse {
  data: IRepasse[]
  gridChildren?: ReactNode
}

const ResumoRepasse: React.FC<InterfaceResumoRepasse> = ({
  data,
  gridChildren
}) => {
  return (
    <DataGrid
      dataSource={data}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      hoverStateEnabled={true}
      columnHidingEnabled={isMobile}
      style={{ marginTop: 23, width: '100%' }}
    >
      {gridChildren}

      <Column
        dataField="TotalBrutoNF"
        caption="Total Bruto NF"
        cellRender={moneyRender}
        width="200"
      />
      <Column
        dataField="TotalDeducoes"
        caption="Total de Deduções"
        cellRender={moneyRender}
        width="200"
      />
      <Column
        dataField="TotalRecebido"
        caption="Total Recebido"
        cellRender={moneyRender}
        width="200"
      />
      <Column
        dataField="ValorRepassado"
        caption="Valor Repasse"
        cellRender={moneyRender}
        width="200"
      />
      <Summary>
        <TotalItem
          column="TotalBrutoNF"
          summaryType="sum"
          displayFormat={e => {
            return convertToMoney(e)
          }}
        />
      </Summary>
    </DataGrid>
  )
}

export default ResumoRepasse
