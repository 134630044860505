export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

export const min_months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
]

export function getMonth(month, type) {
  if (typeof month === 'string') {
    month = parseInt(month)
  }

  return type === 'fullmonth' ? months[month - 1] : min_months[month - 1]
}

export function getMonths(type) {
  return type === 'fullmonth' ? months : min_months
}
