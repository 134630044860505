import React, { useState, useEffect } from 'react'
import { ProgressBar, Form, Row, Col } from 'react-bootstrap'

import Name from './name'
import Email from './email'
import { getUserData } from '../../hooks'

const AccountPage = () => {
  const userData = getUserData()

  const [email, setEmail] = useState('')

  useEffect(() => {
    if (userData[0] !== null) {
      setEmail(userData[0].Email)
    }
  }, [userData])

  return (
    <main id="conta">
      <Name />
      <Email email={email} />
    </main>
  )
}

export default AccountPage
