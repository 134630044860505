import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaSignOutAlt, FaBell } from 'react-icons/fa'

import WebMedLogo from '../../assets/images/logo-webmed.png'
import { navigationStore } from '../../store'

export default function Header({ routes }) {
  const [menuVisible, setMenuVisible] = useState(false)
  const { pathname, setPathname } = useContext(navigationStore)

  useEffect(() => {
    verifyTimestamps()
  }, [verifyTimestamps])

  function verifyTimestamps() {
    const token = localStorage.getItem('@webmed-Token').split('.')
    const jsonToken = JSON.parse(atob(token[1]))
    const expiresDate = new Date(jsonToken.expires * 1000)
    const now = new Date()

    if (expiresDate < now) {
      window.location.replace('/logout')
    }

    setTimeout(() => {
      verifyTimestamps()
    }, [1000])
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <button
        className="navbar-toggler"
        id="btn-main-menu"
        type="button"
        onClick={e => {
          setMenuVisible(!menuVisible)
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <img className="logo-login" src={WebMedLogo} alt="" height="30" />

      <button
        className="navbar-toggler bell"
        type="button"
        data-toggle="dropdown"
        data-target="#navbarTogglerNotifications"
        aria-controls="navbarTogglerNotifications"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <FaBell />
        </span>
        <span className="badge badge-notification">0</span>
      </button>

      {menuVisible && (
        <div className="navbar-collapse">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            {routes.map((i, j) => (
              <li
                key={j}
                className={`nav-item ${pathname === i.route && 'active'}`}
                data-toggle="collapse"
                data-target="#navbarToggler"
              >
                <Link
                  className="nav-link"
                  to={i.route}
                  onClick={e => {
                    setPathname(i.route)
                    setMenuVisible(!menuVisible)
                  }}
                >
                  {i.name}
                </Link>
              </li>
            ))}
            <li
              className="nav-item"
              data-toggle="collapse"
              data-target="#navbarToggler"
            >
              <Link to="/logout" className="nav-link" id="sair">
                Sair <FaSignOutAlt />
              </Link>
            </li>
          </ul>
        </div>
      )}
      <div
        className="collapse navbar-collapse dropdown"
        id="navbarTogglerNotifications"
      >
        <ul className="dropdown-menu notifications">
          <span className="triangle" />

          <li className="nav-item">
            <a className="nav-link" href="#">
              Nenhuma notificação
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
