import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

import * as S from './styles'
import convertToMoney from '../../../../functions/convert-to-money'

const GetValue = ({ name, value }) => {
  const [isVisible, setIsVisible] = useState(false)

  function changeVisibility() {
    setIsVisible(!isVisible)
  }

  return (
    <S.GetValueComponent>
      <button onClick={changeVisibility}>
        {isVisible ? <FaEye /> : <FaEyeSlash />}
      </button>
      <span>{name}</span>
      <div>{isVisible ? convertToMoney(value) : <>...</>}</div>
    </S.GetValueComponent>
  )
}

export default GetValue
