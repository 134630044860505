import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import { Link } from 'react-router-dom'

import './styles/conta.css'
import './functions/resize'
import axios from './utils/axios'

import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { FaPencilAlt, FaSignOutAlt, FaFilePdf } from 'react-icons/fa'

const baseImage = require('./assets/baseImage')

let IDUsuario = localStorage.getItem('IDUsuario')
let IDCliente = localStorage.getItem('IDCliente')
var resize = new window.resize()
resize.init()
var imagens
var imagem_atual

var orientacao = null
class Conta extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      file: null,
      image: ''
    }
    this.originalImage = document.getElementById('imagem')
    this.resetImage = document.getElementById('imagem-reset')
    this.alterarSenha = this.alterarSenha.bind(this)
    this.alterarNome = this.alterarNome.bind(this)
    this.redimensionar = this.redimensionar.bind(this)
  }

  componentDidMount = () => {
    // Alterar opção ativa do menu principal
    if (!$('#conta').hasClass('active')) {
      $('#navbarToggler .nav-link.active').removeClass('active')
      $('#conta').addClass('active')
    }

    $('.fa-pencil-alt').click(function () {
      $('#userName').focus()
    })

    $('.close').click(function () {
      $(this).parent().removeClass('show')
      $(this).parent().removeClass('hide')
    })

    IDUsuario = localStorage.getItem('IDUsuario')
    IDCliente = localStorage.getItem('IDCliente')
    this.buscaDados()
  }

  getOrientation(file, callback) {
    var reader = new FileReader()

    reader.onload = function (event) {
      var view = new DataView(event.target.result)

      if (view.getUint16(0, false) !== 0xffd8) return callback(-2)

      var length = view.byteLength,
        offset = 2

      while (offset < length) {
        var marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1)
          }
          var little = view.getUint16((offset += 6), false) === 0x4949
          offset += view.getUint32(offset + 4, little)
          var tags = view.getUint16(offset, little)
          offset += 2

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) === 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little))
        } else if ((marker & 0xff00) !== 0xff00) break
        else offset += view.getUint16(offset, false)
      }
      return callback(-1)
    }

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
  }

  buscaDados = () => {
    let url = 'https://webmedbr.com/phpqueries/dadosUsuario.php'

    let formData = new FormData()
    formData.append('IDUsuario', IDUsuario)

    axios
      .post('/dadosUsuario.php', formData)
      .then(res => {
        const responseJson = res.data

        if (responseJson[0].MostrarContrato === 0) {
          $('#botaoContrato').hide()
          $('#dataRenovacao').hide()
        } else if (responseJson[0].MostrarContrato === 1) {
          $('#dataRenovacao').text(
            'Data de renovação: ' + responseJson[0].DataRenovacao
          )
          $('#botaoContrato').show()
          $('#dataRenovacao').show()
        }
        $('#userName').val(responseJson[0].Nome)
        $('#userEmail').val(responseJson[0].Email)
        if (responseJson[0].Foto !== null) {
          let base = 'data:image/jpeg;base64,' + responseJson[0].Foto
          let orientacaoFoto = responseJson[0].OrientacaoFoto
          this.setState({ image: base })
          var userAgent = window.navigator.userAgent

          if (
            userAgent.match(/iPad/i) ||
            userAgent.match(/iPhone/i) ||
            userAgent.match(/Safari/i)
          ) {
            if (orientacaoFoto === 1) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 0 + 'deg)'
            } else if (orientacaoFoto === 6) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 90 + 'deg)'
            } else if (orientacaoFoto === 3) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 180 + 'deg)'
            } else if (orientacaoFoto === -1) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 270 + 'deg)'
            }
            if (orientacaoFoto === 8) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 270 + 'deg)'
            }
          } else {
            if (orientacaoFoto === 8) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 270 + 'deg)'
            } else if (orientacaoFoto === 6) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 90 + 'deg)'
            } else if (orientacaoFoto === 3) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 180 + 'deg)'
            } else if (orientacaoFoto === -1) {
              document.getElementById('imagem').style.transform =
                'rotate(' + 270 + 'deg)'
            }
          }
          // console.log(orientacaoFoto);
        } else {
          let base = baseImage
        }
        $('#semDados').removeClass('show')
        $('#semDados').addClass('hide')
      })
      .catch(function (error) {
        //notify("Sem dados!", "error", 2000);
        $('#semDados').removeClass('hide')
        $('#semDados').addClass('show')
      })
  }

  contrato(event) {
    let destino =
      'https://webmedbr.com/phpqueries/downloadContrato.php?Cli=' + IDCliente
    //window.location.href = destino;
    window.open(destino, '_blank')
  }

  termos(event) {
    let destino = 'https://webmedbr.com/experimente/TermosdeusoWebMed.pdf'
    //window.location.href = destino;
    window.open(destino, '_blank')
  }

  cancelar() {
    document.getElementById('close').click()
  }

  alterarNome(event) {
    $('userName').val(event.target.value)
    if (event.target.value !== localStorage.getItem('userWebmed')) {
      localStorage.setItem('userWebmed', event.target.value)
      let formData = new FormData()
      formData.append('IDUsuario', IDUsuario)
      formData.append('Usuario', event.target.value)

      axios
        .post('/alterarNome.php', formData)
        .then(responseJson => {
          //notify("Nome Alterado!", "success", 3000);
          $('#nomeAlterado').removeClass('hide')
          $('#nomeAlterado').addClass('show')
        })
        .catch(function (error) {
          //notify("Erro dados!", "error", 2000);
          $('#erroDados1').removeClass('hide')
          $('#erroDados1').addClass('show')
        })
    }
  }

  alterarSenha() {
    if (this.state.currentPassword === '') {
      //notify("Informe a Senha atual!", "error", 2000);
      $('#informeAtual').removeClass('hide')
      $('#informeAtual').addClass('show')
    } else if (this.state.newPassword === '') {
      //notify("Informe a Nova senha!", "error", 2000);
      $('#informeNova').removeClass('hide')
      $('#informeNova').addClass('show')
    } else if (this.state.confirmPassword !== this.state.newPassword) {
      //notify("Confirmação de senha não confere com nova senha!", "error", 2000);
      $('#confirmaSenha').removeClass('hide')
      $('#confirmaSenha').addClass('show')
    } else {
      let formData = new FormData()
      formData.append('IDUsuario', IDUsuario)
      formData.append('SenhaAtual', this.state.currentPassword)
      formData.append('NovaSenha', this.state.newPassword)

      axios
        .post('/alterarSenha.php', formData)
        .then(res => {
          const responseJson = res.data
          if (responseJson === 0) {
            //notify("Senha atual inválida!", "error", 2000);
            $('#senhaInvalida').removeClass('hide')
            $('#senhaInvalida').addClass('show')
          } else if (responseJson === 1) {
            //notify("Senha alterada!", "success", 3000);
            $('#senhaAlterada').removeClass('hide')
            $('#senhaAlterada').addClass('show')
            document.getElementById('close').click()
          }
        })
        .catch(function (error) {
          //notify("Erro dados!", "error", 2000);
          $('#erroDados2').removeClass('hide')
          $('#erroDados2').addClass('show')
        })
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  //quando clicado na imagem, clica no botão file input que fica oculto
  fileinput() {
    document.getElementById('trocarfoto').click()
  }

  redimensionar() {
    // Redimensionando
    resize.photo(imagem_atual, 800, 'dataURL', function (imagem) {
      var porcentagem = ((49 + 1) / 50) * 100

      // Atualizando barra de progresso
      $('#progresso')
        .text(Math.round(porcentagem) + '%')
        .attr('aria-valuenow', porcentagem)
        .css('width', porcentagem + '%')

      //Envia a imagem redimensionada para o BD
      let formData = new FormData()
      formData.append('IDUsuario', IDUsuario)
      formData.append('Foto', imagem)
      formData.append('OrientacaoFoto', orientacao)

      axios
        .post('/alteraFoto.php', formData)
        .then(response => response.json())
        .then(responseJson => {
          //notify("Foto atualizada!", "success", 2000);
          $('#fotoAtualizada').removeClass('hide')
          $('#fotoAtualizada').addClass('show')
          $('#progresso').hide()
        })
        .catch(function (error) {
          //notify("Erro dados!", "error", 2000);
          $('#erroDados3').removeClass('hide')
          $('#erroDados3').addClass('show')
        })
    })

    this.setState({ image: imagens })
  }

  logout() {
    localStorage.setItem('userWebmed', null)
    localStorage.setItem('tknWebmed', null)
    localStorage.setItem('IDCliente', null)
    localStorage.setItem('IDUsuario', null)
    localStorage.setItem('IDMedico', null)
    localStorage.setItem('configuracoes', null)
    //window.location.reload(true);
    window.location.replace('/')
  }

  //Quando a imagem é alterada
  onChange(e) {
    let files = e.target.files

    // console.log(files[0]);
    this.getOrientation(files[0], function (orientation) {
      // console.log("ORIENTACAO");
      // console.log(orientation);
      orientacao = orientation
      var userAgent = window.navigator.userAgent
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Safari/i)
      ) {
        // console.log("SAFARI");
        if (orientacao === 1) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 0 + 'deg)'
        } else if (orientacao === 6) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 90 + 'deg)'
        } else if (orientacao === 3) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 180 + 'deg)'
        } else if (orientacao === -1) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 270 + 'deg)'
        }
        if (orientacao === 8) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 270 + 'deg)'
        }
      } else {
        // console.log("OUTROS");
        if (orientacao === 8) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 270 + 'deg)'
        } else if (orientacao === 6) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 90 + 'deg)'
        } else if (orientacao === 3) {
          document.getElementById('imagem').style.transform =
            'rotate(' + 180 + 'deg)'
        }
      }
    })

    imagem_atual = files[0]
    let reader = new FileReader(files[0])
    reader.readAsDataURL(files[0])
    //console.log(reader);
    reader.onload = e => {
      //this.resetOrientation(e.target.result, orientacao, function(resetBase64Image) {
      //    this.resetImage = resetBase64Image;
      //    console.log("aquiRESET");
      //    console.log(resetBase64Image);
      //});

      imagens = e.target.result
      // console.log(imagens.length);
      if (imagens.length > 0) {
        // Definindo progresso de carregamento
        $('#progresso').attr('aria-valuenow', 0).css('width', '0%')
        //Chama a função redimensionar
        this.redimensionar()
      }
    }
  }

  render() {
    const { img } = this.state
    return (
      <main id="conta">
        <div className="modal" tabIndex="-1" role="dialog" id="alterarSenha">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Alterar senha</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  id="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="currentPassword">Senha atual:</label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={this.handleChange}
                      id="currentPassword"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="newPassword">Nova senha:</label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={this.handleChange}
                      id="newPassword"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirmar senha:</label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={this.handleChange}
                      id="confirmPassword"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.cancelar}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.alterarSenha}
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
          <div
            className="alert alert-danger fade hide"
            role="alert"
            id="informeAtual"
          >
            Informe a senha atual
          </div>
          <div
            className="alert alert-danger fade hide"
            role="alert"
            id="informeNova"
          >
            Informe a nova senha
          </div>
          <div
            className="alert alert-danger fade hide"
            role="alert"
            id="confirmaSenha"
          >
            Confirmação de senha não confere
          </div>
          <div
            className="alert alert-danger fade hide"
            role="alert"
            id="senhaInvalida"
          >
            Senha atual inválida
          </div>
        </div>
        <div
          className="alert alert-danger fade hide"
          role="alert"
          id="semDados"
        >
          Sem dados
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="alert alert-success fade hide"
          role="alert"
          id="nomeAlterado"
        >
          Nome alterado
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="alert alert-danger fade hide"
          role="alert"
          id="erroDados1"
        >
          Erro dados
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="alert alert-success fade hide"
          role="alert"
          id="senhaAlterada"
        >
          Senha alterada
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="alert alert-danger fade hide"
          role="alert"
          id="erroDados2"
        >
          Erro dados
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="alert alert-success fade hide"
          role="alert"
          id="fotoAtualizada"
        >
          Foto atualizada
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          className="alert alert-danger fade hide"
          role="alert"
          id="erroDados3"
        >
          Erro dados
          <button type="button" className="close" aria-label="Fechar alerta">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form className="user-info">
          <div className="form-group" onClick={this.fileinput}>
            <div className="user-avatar text-center shadow">
              <img
                className="img-fluid"
                id="imagem"
                alt=""
                src={`${this.state.image}`}
              />
            </div>
          </div>

          <div onSubmit={this.onFormSubmit}>
            <input
              type="file"
              hidden
              name="file"
              id="trocarfoto"
              onChange={e => this.onChange(e)}
            />
          </div>

          <div className="progress">
            <div
              id="progresso"
              className="progress-bar progress-bar-success"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <div className="form-group row">
            <label htmlFor="name" className="col-2 col-form-label">
              Nome:
            </label>
            <div className="col">
              <input
                type="text"
                className="form-control"
                id="userName"
                onBlur={this.alterarNome}
              />
              <FaPencilAlt />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="email" className="col-2 col-form-label">
              Email:
            </label>
            <div className="col">
              <input
                type="email"
                className="form-control"
                id="userEmail"
                defaultValue="Email"
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <button
              type="button"
              id="botaoContrato"
              className="btn btn-contract shadow col"
              onClick={this.contrato}
            >
              Contrato <FaFilePdf />
            </button>
            <button
              type="button"
              className="btn btn-contract shadow col"
              onClick={this.termos}
            >
              Termos de uso <FaFilePdf />
            </button>
          </div>
          <p id="dataRenovacao" className="renew"></p>
          <button
            type="button"
            className="btn btn-password"
            data-toggle="modal"
            data-target="#alterarSenha"
          >
            Alterar senha
          </button>
          <Link to="/logout" style={{ color: '#e03134' }}>
            Sair <FaSignOutAlt />
          </Link>
        </form>
      </main>
    )
  }
}

export default Conta
