import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'

import { sortTypes } from '../../functions'
import contextConsumer from '../../hooks/context'

// import { Container } from './styles';

const sortFilters = ({ sortFilter, setSortFilter }) => {
  // const context = useContext(contextConsumer);

  return (
    <Dropdown>
      <Dropdown.Toggle
        style={{
          backgroundColor: 'transparent',
          color: '#333',
          border: 'none',
          outline: 'none !important'
        }}
      >
        {sortTypes[sortFilter]}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {sortTypes.map((i, j) => (
          <Dropdown.Item
            key={j}
            onClick={() => {
              setSortFilter(j)
            }}
          >
            {i}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default sortFilters
