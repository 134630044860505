import React from 'react'

import * as S from './styles'

const Select = ({ data, onChange }) => {
  return (
    <S.SelectComponent
      onChange={e => {
        if (typeof onChange !== 'undefined') {
          onChange(e.target.value)
        }
      }}
    >
      {data.map((i, j) => (
        <option key={j} value={i.key}>
          {i.value}
        </option>
      ))}
    </S.SelectComponent>
  )
}

export default Select
