import { AxiosResponse } from 'axios'

import { MasterClass } from './master-class'
import { getData } from '../get-data'
import axios from '../../utils/axiosAPI'

class Procediento extends MasterClass {
  /**
   * @override
   */
  async get(): Promise<IProcedimento[]> {
    let hospitais: IProcedimento[] = []

    const url = `/procedimento${
      this.getIsAdmin() ? `?IDCliente=${this.getIDCliente()}` : ''
    }`

    await getData(url)
      .then(res => {
        hospitais = res as IProcedimento[]
      })
      .catch(err => {
        console.error({ err })
      })

    return hospitais
  }

  async update(procedimento: IProcedimento): Promise<IProcedimento | null> {
    let data: IProcedimento = procedimento

    await axios({
      url: `/procedimento/${procedimento.ID}`,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        data = res.data
      })
      .catch(err => {
        console.error({ err })
      })

    return data
  }

  async delete(id: number): Promise<AxiosResponse | null> {
    let response: AxiosResponse | null = null

    await axios
      .delete(`/procedimento/${id}`)
      .then(res => {
        response = res.data
      })
      .catch(err => {
        console.error({ err })
      })

    return response
  }
}

export default new Procediento()
