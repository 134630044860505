import styled from 'styled-components'
import { Button, Form } from 'react-bootstrap'

export const ImageComponent = styled.img`
  width: 80%;
  margin-bottom: 2rem;
  align-self: center;
`

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  padding: 50px;
  background-color: var(--blue);
  flex-direction: column;
  align-content: center;
  justify-content: center;
  justify-items: center;

  @media screen and (min-width: 800px) {
    padding: 100px;
  }
`

export const FormWrapper = styled.form`
  input {
    margin-top: 10px;
    background-color: hsla(0, 0%, 100%, 0.59) !important;
    border: 1px solid hsla(0, 0%, 100%, 0.59);
    color: var(--black);
    outline: none !important;
    box-shadow: none !important;
  }

  input[type='submit'] {
    border-color: #fff;
    background-color: #fff;
    color: var(--dark-blue);
    width: 100%;
    font-weight: bold;
  }
`

export const ForgotPasswordComponent = styled.div`
  width: 100%;
  margin-top: 10px;

  a {
    color: #fff !important;
    text-decoration: none !important;
    float: right;
    font-size: 12px;
  }
`

export const DemoButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
  background-color: transparent !important;
  border: 2px solid #fff !important;
`
