import { createContext, Dispatch, SetStateAction } from 'react'

export interface InterfaceFile {
  index: number
  file: File
  repasse: any
}

interface InterfaceFileList {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  isXML: boolean
  setIsXML: Dispatch<SetStateAction<boolean>>
  files: InterfaceFile[]
  setFiles: Dispatch<SetStateAction<InterfaceFile[]>>
}

const NotaContext = createContext<InterfaceFileList>({} as InterfaceFileList)

export { NotaContext }
