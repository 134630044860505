import React, { useState, useEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'

import * as S from './styles'
import Body from './body'
import GetVal from './get-val'
import { getMonth } from 'functions/pt-br'
import { getTokenDataInfo } from 'hooks'
import { producao } from 'functions'
import context from './store'
import { convertToDate, subDate } from 'pages/graficos/functions'

const ProducaoMedica: React.FC = () => {
  const [initialDate, setInitialDate] = useState<string | undefined>()
  const [monthNumber, setMonthNumber] = useState<string>()
  const [data, setData] = useState<InterfaceProducao[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [bodyVisible, setBodyVisible] = useState<boolean>(false)
  const [valorOrcado, setValorOrcado] = useState(0)
  const [valorRecebido, setValorRecebido] = useState(0)
  const [finalData, setFinalDate] = useState<string>('')

  const { height } = useContext(context)

  const userInfo = getTokenDataInfo()

  useEffect(() => {
    const date = new Date()
    const year = date.getFullYear()
    let month: string | number = date.getMonth() + 1
    let day: string | number = date.getDate()

    const _f = new Date()
    _f.setDate(date.getDate() - 7)

    if (month < 10) {
      month = `0${month}`
    }

    if (day < 10) {
      day = `0${day}`
    }

    setInitialDate(`${year}-${month}-${day}`)
    setFinalDate(`${_f.getFullYear()}-${_f.getMonth() + 1}-${_f.getDate()}`)
    setMonthNumber(month as string)
  }, [])

  useEffect(() => {
    loadInfo()
  }, [userInfo])

  async function loadInfo() {
    const IDMedico = userInfo?.IDMedico

    if (typeof IDMedico === 'undefined') {
      return
    }

    setIsLoading(true)
    // setData([])

    await producao
      .get({
        IDMedico,
        DataInicial: subDate(7),
        DataFinal: convertToDate(new Date())
      })
      .then(res => {
        const _r = res.sort((i, j) => {
          return new Date(i.Data) < new Date(j.Data) ? 1 : -1
        })

        setData(_r)
      })

    setIsLoading(false)
  }

  function getDay(date: string) {
    return parseInt(date.split('-')[2])
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      let _tmpOrcado = 0
      let _tmpValorRecebido = 0

      data.map(i => {
        _tmpOrcado += parseFloat(i?.ValorOrcado || '0')
        _tmpValorRecebido = parseFloat(i?.ValorRecebido || '0')
      })

      setValorOrcado(_tmpOrcado)
      setValorRecebido(isNaN(_tmpValorRecebido) ? 0 : _tmpValorRecebido)
    }
  }, [data])

  return (
    <Container style={{ maxHeight: height }}>
      <S.ProducaoCardHeaderWrapper maxHeight={height}>
        <strong>PRODUÇÃO MÉDICA</strong>
        <div>
          <span>
            {initialDate ? (
              <>
                {finalData?.split('-')[2]}/
                {getMonth(finalData?.split('-')[1] || 1, 'minmonth')}
                {' - '}
                {initialDate?.split('-')[2]}/
                {getMonth(monthNumber || 1, 'minmonth')}
              </>
            ) : (
              <>Loading</>
            )}
          </span>
          <button
            onClick={e => {
              setBodyVisible(!bodyVisible)
            }}
          >
            <S.ArrowDownComponent isVisible={bodyVisible} />
          </button>
        </div>
      </S.ProducaoCardHeaderWrapper>

      <Body
        {...{ data, isLoading }}
        month={getMonth(monthNumber || 1, 'minmonth')}
        isVisible={bodyVisible}
        maxHeight={height}
      />

      <GetVal name="Valor Tabela" value={valorOrcado} />
      <GetVal name="Valor Recebido" value={valorRecebido} />
    </Container>
  )
}

export default ProducaoMedica
