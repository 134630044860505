import { getData } from '../get-data'

export default {
  async store(
    {
      ID,
      IDCliente,
      Convenio,
      NomeConvenio,
      Endereco,
      Cidade,
      Estado,
      Ex
    }: IConvenio,
    { idclient, category }: UserState
  ) {
    //
  },

  async get(idclient?: number, isAdm = false): Promise<IConvenio[]> {
    const link = '/convenio' + (isAdm ? `?IDCliente=${idclient}` : '')
    let convenios: IConvenio[] = []

    await getData(link).then(res => {
      convenios = res
    })

    convenios.sort((a, b) => {
      if (a.NomeConvenio < b.NomeConvenio) {
        return -1
      }
      if (a.NomeConvenio > b.NomeConvenio) {
        return 1
      }
      return 0
    })

    return convenios
  },

  async update() {
    //
  },
  async delete() {
    //
  }
}
