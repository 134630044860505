import React, { useEffect, useState, useContext } from 'react'

import { CollapseProps } from './types'
import { tabelaCBHPM } from 'functions/network'
import * as S from './styles'
import { StoreConvenio } from './store/convenio'
import { convertToMoney } from 'functions'

const Collapse: React.FC<CollapseProps> = ({ data, load }) => {
  const [values, setValues] = useState<any | any[]>([])

  const { convenios } = useContext(StoreConvenio)

  useEffect(() => {
    if (!load) {
      return
    }

    tabelaCBHPM.getPayment(data.ID).then(setValues).catch(console.warn)
  }, [data, load])

  return (
    <S.ListComponent>
      <li>
        <strong>Descrição:</strong>
        <span>{data.DescricaoAMB}</span>
      </li>

      <li>
        <strong>CBHPM:</strong>
        <span>{data.CodigoCBHPM}</span>
      </li>

      <li>
        <strong>AMB:</strong>
        <span>{data.CodigoAMB}</span>
      </li>

      <li>
        <strong>TUSS:</strong>
        <span>{data.CodigoTUSS}</span>
      </li>

      {Object.keys(values).length > 0 &&
        values.map(i => (
          <div key={i.ID}>
            <li>
              <strong>Convênio:</strong>
              <span>
                {convenios.find(j => j.ID === i.IDConvenio)?.Convenio}
              </span>
            </li>

            <li>
              <strong>Valor</strong>
              <span>{convertToMoney(i.Valor)}</span>
            </li>
          </div>
        ))}
    </S.ListComponent>
  )
}

export default Collapse
