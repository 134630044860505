export const filterTypes = [
  [
    'R$'
    // "Quantidade"
  ],
  [
    'Convênio',
    'Hospital'
    // "Procedimento"
  ]
]

export const sortTypes = [
  'Atendimentos',
  'A Receber',
  'Recebimentos',
  'Repasses'
]
