import React from 'react'
import { Table } from 'react-bootstrap'

import convertToMoney from 'functions/convert-to-money'
import * as S from './styles'

interface InterfaceData {
  data: InterfaceProducao[]
  isLoading: boolean
  month: string
  isVisible: boolean
  returnVal?: () => void
  maxHeight: number
}

const Body: React.FC<InterfaceData> = ({
  data,
  isLoading,
  month,
  isVisible,
  maxHeight
}) => {
  return isVisible ? (
    <S.BodyComponent maxHeight={maxHeight}>
      <ul>
        {isLoading && <>Carregando...</>}
        {!isLoading && Object.keys(data).length === 0 && (
          <>Nenhuma produção para o mês atual</>
        )}
        {data.map((i, j) => (
          <li key={j}>
            <strong>
              {i.Data?.split('-')[2]}/{month}
            </strong>

            <Table>
              <tr>
                <td>Paciente</td>
                <td>Valor Recebido R$:</td>
                <td>Valor Tabela R$:</td>
              </tr>
              <tr>
                <td>{i.Paciente?.substr(0, 20)}</td>
                <td>
                  <strong>{convertToMoney(i.ValorRecebido as string)}</strong>
                </td>
                <td>
                  <strong>{convertToMoney(i.ValorOrcado as string)}</strong>
                </td>
              </tr>
            </Table>
          </li>
        ))}
      </ul>
    </S.BodyComponent>
  ) : (
    <></>
  )
}

export default Body
