import styled from 'styled-components'

export const ListStyle = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: space-between;

  li {
    list-style: none;
    /* border-right: 1px solid #333; */
    width: auto;
    height: 1.4em;
    overflow: hidden;
  }
`

export const ListComponent = styled.ul`
  display: block;
  padding: 6px 12px;

  li {
    display: flex;
    justify-content: space-between;
    margin: 2px;
    padding-left: 8px;
    border-left: 1px solid #aaaa;

    span {
      text-align: right;
    }
  }

  div {
    margin-top: 12px;
    border-top: 1px solid #aaaa;
  }
`
