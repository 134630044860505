import React, { useState, useEffect } from 'react'

import * as S from './styles'

interface InterfaceSwitch {
  changed?(value: boolean): void
}

export const Switch = ({ changed }: InterfaceSwitch) => {
  const [value, setValue] = useState(true)

  useEffect(() => {
    changed && changed(true) // changed(value)
  }, [value])

  return (
    <S.Switch>
      <div className="onoffswitch">
        <input
          type="checkbox"
          name="onoffswitch"
          className="onoffswitch-checkbox"
          id="myonoffswitch"
          onChange={e => setValue(e.target.checked)}
          // defaultChecked={value}
          checked
        />
        <label className="onoffswitch-label" htmlFor="myonoffswitch">
          <span className="onoffswitch-inner"></span>
          <span className="onoffswitch-switch"></span>
        </label>
      </div>
    </S.Switch>
  )
}
export default Switch
