import React, { useEffect, useState } from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap'

import * as Component from './components'
import * as S from './styles'
import Widgets from '../graficos/widgets'

const Home = () => {
  const IDCliente = localStorage.getItem('IDCliente')
  const [webmed, isWebMed] = useState(false)
  useEffect(() => {
    if (IDCliente === '62') {
      isWebMed(true)
    }
  }, [])
  return (
    <Container style={{ marginTop: '80px', marginBottom: '50px' }}>
      <div>
        <strong>Extrato Médico:</strong>
        <S.CardComponent>
          <Component.ExtratoMedico />
        </S.CardComponent>
      </div>

      <S.CardComponent className="mt-3">
        <Component.ProducaoMedica />
      </S.CardComponent>

      <S.CardComponent className="mt-3">
        <Component.ComparativoMensal />
      </S.CardComponent>
      {webmed && <Widgets />}
    </Container>
  )
}

export default Home
