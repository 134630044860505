import React from 'react'

const Logout = () => {
  React.useEffect(() => {
    clearStorage().then(() => {
      window.location.replace('/')
    })
  }, [])

  async function clearStorage() {
    await localStorage.clear()
  }

  return <>Saindo...</>
}

export default Logout
