import React, { useState, useEffect } from 'react'

import axiosAPI from 'utils/axiosAPI'
import Producao from './producao'

const RepasseProducao = ({ ID }: { ID: number }) => {
  const [data, setData] = useState<{ IDProducao: number }[]>([])

  useEffect(() => {
    axiosAPI.get(`/repasse/${ID}/producao`).then(e => {
      setData(e?.data.data)
    })
  }, [])

  return (
    <div>
      {data.map(i => (
        <Producao ID={i.IDProducao} key={i.IDProducao} />
      ))}
    </div>
  )
}

export default RepasseProducao
