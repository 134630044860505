import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import './styles/styles.css'
import './styles/index.css'
import './styles/App.css'

// ReactDOM.render(<App />, document.getElementById("root"));
const isDev = process.env.NODE_ENV === 'development'
const rootDiv = document.getElementById('root')

ReactDOM.render(<App />, rootDiv)

if (isDev && module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
