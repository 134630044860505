import styled from 'styled-components'

export const GraphicContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 18px;
  box-shadow: 0 0 5px #3333;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const GraphicDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    span {
      color: var(--blue);
    }
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: var(--blue);
  margin: 20px;
  font-weight: bold;
`
