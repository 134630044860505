import styled from 'styled-components'
import { FaArrowDown } from 'react-icons/fa'

interface ArrowDownComponentInterface {
  isVisible?: boolean | true
}

interface BodyInterface {
  maxHeight: number
}

export const ProducaoCardHeaderWrapper = styled.div<BodyInterface>`
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  font-size: 0.9rem;
  max-height: ${props => props.maxHeight}px;

  div {
    display: block;

    span {
      margin: 15px;
    }

    button {
      padding: 2px 9px;
      border-radius: 5rem;
      border: none;
      outline: none;
    }
  }
`

export const BodyComponent = styled.div<BodyInterface>`
  width: 100%;
  max-height: ${props => props.maxHeight - 110}px;
  overflow-y: scroll;
  padding-top: 15px;
  padding-bottom: 15px;

  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      margin-top: 5px;
      border-top: 1px solid #3333;
      padding-top: 15px;

      div {
        margin-top: 12px;
      }

      td {
        font-size: 0.9em;
      }

      strong {
        margin-left: 15px;
      }
    }
  }
`

export const ArrowDownComponent = styled(
  FaArrowDown
)<ArrowDownComponentInterface>`
  transform: rotate(${props => (props.isVisible ? 0 : 180)}deg);
  transition: 0.4s;
`

export const GetValueComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align: center;
  align-items: center;

  button {
    color: #f83;
    background-color: transparent;
    font-size: 1.5em;
    outline: none;
    border: none;
  }
`
