import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import {
  FaChartBar,
  FaInfoCircle,
  FaUserCircle,
  FaHome,
  FaSearch
} from 'react-icons/fa'

import { navigationStore as NavigationStore } from './store'
import { Header, Footer } from './components'
import * as Pages from './pages'
import Conta from './Conta'

function Main() {
  const [pathname, setPathname] = useState(window.location.pathname)
  const isLogged = localStorage.getItem('@webmed-Token') !== null

  const routes = [
    {
      route: '/',
      component: Pages.Home,
      name: 'Home',
      icon: FaHome
    },
    {
      route: '/graficos',
      component: Pages.NovosGraficos,
      name: 'Gráficos',
      icon: FaChartBar
    },
    {
      route: '/sobre',
      component: Pages.About,
      name: 'Sobre',
      icon: FaInfoCircle
    },
    {
      route: '/minha-conta',
      component: Conta,
      name: 'Minha Conta',
      icon: FaUserCircle
    },
    {
      route: '/search',
      component: Pages.Search,
      name: 'Pesquisa',
      icon: FaSearch
    },
    {
      route: '/cbhpm',
      component: Pages.CBHPM,
      name: 'CBHPM',
      icon: FaSearch
    }
  ]

  const footer_routes = routes.slice(0, 4)

  useEffect(() => {
    if (!isLogged && window.location.pathname !== '/') {
      window.location.replace('/')
    }
  }, [isLogged])

  return (
    <>
      {!isLogged ? (
        <Pages.Login />
      ) : (
        <NavigationStore.Provider value={{ pathname, setPathname }}>
          <Router>
            <Header routes={routes} />
            {routes.map((i, j) => (
              <Route key={j} exact path={i.route} {...i} />
            ))}
            <Route path="/logout" component={Pages.Logout} />
            <Route path="/extrato-medico" component={Pages.ExtratoMedico} />
            <Footer routes={footer_routes} />
          </Router>
        </NavigationStore.Provider>
      )}
    </>
  )
}

export default Main
