import React, { useState, useEffect } from "react";
import { Card, Accordion } from 'react-bootstrap';

import { AccordionComponent, PatientDetails, PatientInfo } from 'pages/graficos/components/date-separator/styles';
import axiosAPI from "utils/axiosAPI";
import { convertToMoney } from "functions";

const Producao = ({ ID }: { ID: number }) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    axiosAPI.get(`/producao/${ID}`)
      .then(e => {
        setData(e.data.data)
      })
  }, [])

  return (
    <Card.Body style={{ zIndex: 20, margin: 0, padding: 0 }}>
      <Accordion.Toggle as={AccordionComponent} eventKey={`pat-${ID}`}>
        {data?.Paciente}
        
        <span>
          {convertToMoney(data?.ValorOrcado)}
        </span>
      </Accordion.Toggle>

      <PatientInfo eventKey={`pat-${ID}`}>
        <PatientDetails style={{ margin: 0, padding: 0}}>
          <>
            <PatientDetails style={{ margin: 0, padding: 0}}>
              <p>
                <strong>Convênio</strong>
                <span>{data?.Convenio?.Convenio}</span>
              </p>

              <p>
                <strong>Status</strong>
                <span>{data?.Status?.Status}</span>
              </p>

              <p>
                <strong>Procedimento</strong>
                <span style={{ textAlign: 'right' }}>{data?.Procedimento?.Descricao! || ''}</span>
              </p>

              <p>
                <strong>Paciente</strong>
                <span>{data?.Paciente}</span>
              </p>

              {/* <p>
                <strong>Valor Orçado</strong>
                <span>
                  {convertToMoney(data?.ValorOrcado)}
                </span>
              </p> */}
            </PatientDetails>

            {data?.ProcedimentosComplementares && (
              <>
                <strong>Procedimentos:</strong>

                <ul style={{ margin: 5, padding: 5 }}>
                  {data?.ProcedimentosComplementares.map(i => (
                    <li key={i.ID} style={{ listStyle: 'none' }}>
                      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong>Procedimento:</strong>
                        <span style={{ textAlign: 'right' }}>
                          {i.Procedimento?.Descricao}
                        </span>
                      </p>
                      <p style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <strong>Valor</strong>
                        <span>
                          {convertToMoney(i.Procedimento?.Valor)}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        </PatientDetails>
      </PatientInfo>
    </Card.Body>
  )
}

export default Producao;
