import axios from '../utils/axiosAPI'

/*
 *
 * @param {string} link URL que busca as informações
 * @param {number} percent número da porcentagem em que "andas"
 * @param {boolean} loading mostra se está carregando
 * @param {object} data_old dados carregados anteriormente
 *
 * @returns {object}
 */
export function getData(link, percent, loading, data_old) {
  let totalPages = 1

  if (loading !== undefined) {
    loading(true)
  }

  /**
   *
   * @desc busca as informações, caso tenha uma segunda página, ele buscará a primeira,
   * irá salvar as informações buscadas antes, e depois busca a próxima página,
   * fazendo assim até que não tenha mais páginas.
   */
  return new Promise((resolve, reject) => {
    axios.get(link).then(result => {
      try {
        const res = result.data
      } catch (err) {
        console.log({
          err
        })
        resolve([])
      }

      const res = result.data
      if (process.env.NODE_ENV === 'development') {
        console.log({
          res
        })
      }

      if (typeof res === 'undefined') {
        resolve()
      }
      // const new_data = data_old?.concat(res.data)
      let new_data = []

      try {
        new_data = res.data
      } catch (err) {
        console.log({
          err
        })
      }

      if (data_old !== undefined) {
        new_data = new_data.concat(data_old)
      }

      if (res.Meta !== undefined) {
        totalPages = res.Meta.TotalPages
        const currentPage = res.Meta.CurrentPage

        if (percent !== undefined) {
          percent(parseInt((currentPage / totalPages) * 100))
        }

        if (res.Links.Next !== null) {
          if (loading !== undefined) {
            loading(false)
          }

          getData(res.Links.Next, percent, loading, new_data).then(res =>
            resolve(res)
          )
        } else {
          console.log({
            new_data
          })
          resolve(new_data)
          return new_data
        }
      }

      if (res.meta !== undefined) {
        totalPages = res.meta.TotalPages
        const currentPage = res.meta.CurrentPage

        if (percent !== undefined) {
          percent(parseInt((parseInt(currentPage) / totalPages) * 100))
        }

        if (
          res.links.next !== null ||
          res.links.next === 'https://api.sandbox.webmedbr.com/medico?page=1'
        ) {
          if (loading !== undefined) {
            loading(false)
          }

          console.log({
            new_data
          })

          getData(res.links.next, percent, loading, new_data).then(res => {
            resolve(res)
          })
        } else {
          resolve(new_data)
        }
      }
    })
  })
}
