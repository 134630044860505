import React from 'react'

import { Spinner, Spinn, Circles } from './styles'

interface InterafaceLoading {
  loading: boolean
}

const Loading: React.FC<InterafaceLoading> = ({ loading }) => {
  return (
    <>
      {loading && (
        <Spinner>
          <Spinn>
            <Circles />
          </Spinn>
        </Spinner>
      )}
    </>
  )
}

export default Loading
