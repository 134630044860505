import React, { useState, useEffect } from 'react'

import { filterTypes } from '../../functions'
import * as S from './styles'

const Filters = ({ updateFilters }) => {
  const [filter, setFilter] = useState({
    monetary: filterTypes[0][0],
    type: filterTypes[1][0]
  })

  useEffect(() => {
    updateFilters(filter)
  }, [filter, updateFilters])

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.href)

    setFilter({
      monetary: searchParam.get('monetary') ?? filter.monetary,
      type: searchParam.get('type') ?? filter.type
    })
  }, [])

  function updateState(arr, arg) {
    setFilter({
      ...filter,
      [arr === 0 ? 'monetary' : 'type']: arg
    })
  }

  return (
    <S.FlatListComponent>
      {filterTypes.map((i, j) => (
        <S.ButtonListComponent key={j}>
          {i.map((k, l) => (
            <S.ButtonComponent
              active={filter[j === 0 ? 'monetary' : 'type'] === k}
              isFirst={l === 0}
              isLast={l === Object.keys(i).length - 1}
              onClick={() => updateState(j, k)}
              key={l}
            >
              {k}
            </S.ButtonComponent>
          ))}
        </S.ButtonListComponent>
      ))}
    </S.FlatListComponent>
  )
}

export default Filters
