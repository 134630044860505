import React, { useState, useEffect, useRef } from 'react'
import { Button, Accordion } from 'react-bootstrap'

import ScrollResult from './scrollresult'
import { SearchProps } from './@types'
import * as S from './styles'
import * as C from 'pages/graficos/components'
import axios from 'utils/axiosAPI'
import Result from './result'

const Results = ({ searchTerm, handleNewSearch, newSearch }: SearchProps) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<InterfaceProducao[]>([])
  const [totalPage, setTotalPage] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (newSearch) {
      getNewData(`/producao?Paciente=${encodeURI(searchTerm.toLowerCase())}`)
      handleNewSearch()
    } else {
      // getData(`/producao?Paciente=${encodeURI(searchTerm.toLowerCase())}&page=${page}`)
    }
  }, [newSearch])

  useEffect(() => {
    if (newSearch) {
      return
    }
    if (page === 1) {
      return
    }
    if (page > totalPage) {
      return
    }
    const url = `/producao?page=${page}&Paciente=${encodeURI(
      searchTerm.toLowerCase()
    )}`

    if (page != totalPage + 1) {
      getData(url)
    }
  }, [page, searchTerm])

  async function getNewData(url: string) {
    setData([])
    setLoading(true)
    await axios
      .get(url)
      .then(e => {
        setData(e.data.data)
        setTotalPage(e.data.Meta.TotalPages)
      })
      .catch(console.error)
    setLoading(false)
  }

  async function getData(url: string) {
    setLoading(true)
    await axios
      .get(url)
      .then(e => {
        console.log(e)
        setData([...data, ...e.data.data])
      })
      .catch(console.error)
    setLoading(false)
  }

  function updatePageNumber(isCloseToEnd: boolean) {
    if (isCloseToEnd) {
      setPage(p => p + 1)
    }
  }

  return (
    <>
      <C.Loading {...{ loading }} />
      <ScrollResult closeToEnd={updatePageNumber}>
        <Accordion>
          {data
            .sort(
              ({ Paciente }, b) => parseInt(Paciente) - parseInt(b.Paciente)
            )
            .map((i, j) => (
              <Result i={i} j={j} key={j} />
            ))}
        </Accordion>
      </ScrollResult>
    </>
  )
}

const SearchPaciente = () => {
  const dataRef = useRef<HTMLInputElement>(null)
  const [searchTerm, setSearchTearm] = useState<string>('')
  const [newSearch, setNewSearch] = useState(false)
  const handleNewSearch = () => setNewSearch(!newSearch)

  function search() {
    handleNewSearch()
    setSearchTearm(dataRef.current?.value as string)
  }

  return (
    <S.SearchComponent>
      <div className="row">
        <div className="col-8">
          <input
            type="text"
            placeholder="Busca"
            defaultValue=""
            autoComplete="off"
            ref={dataRef}
          />
        </div>

        <div className="col-3">
          <Button onClick={search}>Procurar</Button>
        </div>
      </div>

      <Results {...{ searchTerm, handleNewSearch, newSearch }} />
    </S.SearchComponent>
  )
}

export default SearchPaciente
