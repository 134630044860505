function convertToDate(date) {
  const d = new Date(date)

  const year = d.getFullYear()
  const month = getAboveTen(d.getMonth() + 1)
  const day = getAboveTen(d.getDate())

  return [year, month, day].join('-')
}

function subDate(days) {
  var date = new Date()
  date.setDate(date.getDate() - days)

  return convertToDate(date)
}

export function getAboveTen(num) {
  return num < 10 ? `0${num}` : num
}

export function getFirstDayInMonth() {
  return subDate(new Date().getDate() - 2)
}

function lastMonth() {
  const date = new Date()

  const year = date.getFullYear()
  const month = getAboveTen(date.getMonth())

  const init = [year, month, '01'].join('-')
  const final = [year, month, new Date(year, month, 0).getDate()].join('-')

  return [init, final]
}

export const today = convertToDate(new Date())

const dates = [
  {
    value: 0,
    label: 'Hoje',
    name: 'Hoje',
    initialDate: today,
    finalDate: today
  },
  {
    value: 1,
    label: 'Ontem',
    name: 'Ontem',
    initialDate: convertToDate(subDate(0)),
    finalDate: convertToDate(subDate(0))
  },
  {
    value: 2,
    label: 'Últimos 7 dias',
    name: 'Últimos 7 dias',
    initialDate: convertToDate(subDate(6)),
    finalDate: today
  },
  {
    value: 3,
    label: 'Últimos 15 dias',
    name: 'Últimos 15 dias',
    initialDate: convertToDate(subDate(14)),
    finalDate: today
  },
  {
    value: 4,
    label: 'Mês Atual',
    name: 'Mês Atual',
    initialDate: convertToDate(getFirstDayInMonth()),
    finalDate: today
  },
  {
    value: 5,
    label: 'Mês Anterior',
    name: 'Mês Anterior',
    initialDate: lastMonth()[0],
    finalDate: lastMonth()[1]
  },
  {
    value: 6,
    label: 'Próximos 30 Dias',
    name: 'Próximos 30 Dias',
    initialDate: today,
    finalDate: convertToDate(subDate(-31))
  }
]

export { convertToDate, subDate, dates }
