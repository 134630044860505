import React, { useState, useEffect, useRef } from 'react'
import { FaPlus, FaCamera } from 'react-icons/fa'
import { Modal, Button, Form } from 'react-bootstrap'

import * as S from '../styles'
import { today } from '../../functions'
import axios from '../../../../utils/axios'

const InsertProduction = () => {
  const IDCliente = localStorage.getItem('IDCliente')

  const [show, setShow] = useState(false)
  const [procedimentos, setProcedimentos] = useState([])
  const [convenios, setConvenios] = useState([])
  const [hospitais, setHospitais] = useState([])

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const onHide = () => setShow(false)

  const imageRef = useRef(null)
  const inputFileRef = useRef(null)
  const convenioRef = useRef(null)
  const procedimentoRef = useRef(null)
  const hospitalRef = useRef(null)
  const pacienteRef = useRef(null)
  const dataRef = useRef(null)

  function fileinput() {
    inputFileRef.current.click()
  }

  useEffect(() => {
    const formData = new FormData()
    formData.append('IDCliente', IDCliente)

    axios
      .post('/procedimentos.php', formData)
      .then(res => {
        setProcedimentos(res.data)
      })
      .catch(console.warn)
  }, [IDCliente])

  useEffect(() => {
    const formData = new FormData()
    formData.append('IDCliente', IDCliente)

    axios
      .post('/convenios.php', formData)
      .then(res => {
        setConvenios(res.data)
      })
      .catch(console.warn)
  }, [IDCliente])

  useEffect(() => {
    const formData = new FormData()
    formData.append('IDCliente', IDCliente)

    axios
      .post('/hospitais.php', formData)
      .then(res => {
        setHospitais(res.data)
      })
      .catch(console.warn)
  }, [IDCliente])

  function insertData() {
    const formData = new FormData()

    formData.append('IDCliente', IDCliente)
    formData.append('IDMedico', localStorage.getItem('IDMedico'))
    formData.append('IDConvenio', convenioRef.current.value)
    formData.append('IDProcedimento', procedimentoRef.current.value)
    formData.append('IDHospital', hospitalRef.current.value)
    formData.append('Paciente', pacienteRef.current.value)
    formData.append('Data', dataRef.current.value)
    formData.append('Guia', imageRef.current.value)

    axios.post('inserirLancamento2.php', formData).then(res => {
      handleClose()
    })
  }

  return (
    <>
      <S.ButtonComponent onClick={handleShow} style={{ paddingBottom: '10px' }}>
        <FaPlus />
      </S.ButtonComponent>

      <Modal {...{ show, onHide }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Incluir Atendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            Data:
            <input
              type="date"
              ref={dataRef}
              defaultValue={today}
              className="form-control"
            />
          </Form.Group>

          <Form.Group>
            <label className="form-label">Convênio:</label>

            <select id="convenio" ref={convenioRef} className="form-control">
              {convenios.map((i, j) => (
                <option key={j} value={i.ID}>
                  {i.Convenio}
                </option>
              ))}
            </select>
          </Form.Group>

          <div className="form-group">
            <label className="form-label">Procedimento principal:</label>

            <select ref={procedimentoRef} className="form-control">
              {procedimentos?.map((i, j) => (
                <option key={j} value={i.ID}>
                  {i.Sigla}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="form-label">Hospital:</label>

            <select ref={hospitalRef} className="form-control">
              {hospitais.map((i, j) => (
                <option key={i} value={i.ID}>
                  {i.Hospital}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label className="form-label">Nome do paciente:</label>

            <input
              type="text"
              className="form-control"
              ref={pacienteRef}
              // onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="form-label">Guia de atendimento:</label>

            <input type="file" hidden ref={inputFileRef} />

            <div className="progress">
              <div
                className="progress-bar progress-bar-success"
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            {/* <img hidden className="img-fluid" ref={imageRef} /> */}

            <button
              onClick={fileinput}
              className="form-control btn add-picture-btn"
            >
              <FaCamera />
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={insertData}>
            Criar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InsertProduction
