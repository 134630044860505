import React, { useRef, useState } from 'react'
import { Form } from 'react-bootstrap'

import * as S from './styles'
import axios from '../../utils/axiosAPI'
import saveData from './functions/save-data'

const Login = () => {
  const emailRef = useRef(null)
  const passRef = useRef(null)

  const [remainingAttempts, setRemainingAttempts] = useState()
  const [message, setMessage] = useState()

  function onSubmit(event) {
    event.preventDefault()

    attemptLogin()
  }

  async function attemptLogin() {
    const Email = emailRef.current.value
    const Senha = passRef.current.value

    await axios
      .post('/auth/login', {
        Email,
        Senha
      })
      .then(res => {
        if (res.status === 200) {
          if (saveData(res.data)) {
            window.location.reload()
          }
        }
      })
      .catch(err => {
        const data = err.response.data

        if (data.remainingAttempts) {
          setRemainingAttempts(data.remainingAttempts)
        }

        if (data.message) {
          setMessage(data.message)
        }
      })
  }

  async function demo() {
    await axios.get('/auth/demo').then(res => {
      saveData(res.data)
      window.location.reload()
    })
  }

  return (
    <S.Container>
      <S.ImageComponent
        src="/assets/images/logo-webmed.png"
        alt="Logo Webmed"
      />

      <S.FormWrapper {...{ onSubmit }}>
        <Form.Control type="email" ref={emailRef} placeholder="Usuário" />
        <Form.Control type="password" ref={passRef} placeholder="Senha" />

        <S.ForgotPasswordComponent>
          <a href="#">Esqueci Minha Senha</a>
        </S.ForgotPasswordComponent>

        <input type="submit" value="Entrar" className="btn" />
      </S.FormWrapper>

      {message && <div>{message}</div>}

      {remainingAttempts && <div>Número de tentaivas {remainingAttempts}</div>}

      <S.DemoButton onClick={demo}>Demonstração</S.DemoButton>
    </S.Container>
  )
}

export default Login
