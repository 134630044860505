import React from 'react'

import '../../styles/Sobre.css'
import { version } from '../../../package.json'

const AboutPage = () => {
  return (
    <main id="sobre" className="shadow">
      <img
        className="webmed-logo"
        src="/assets/images/logo-webmed-verde.png"
        alt=""
      />
      <small>v{version}</small>
      <h2>Quem Somos</h2>
      <p>
        O WebMed® é uma plataforma totalmente web, com marca e produto
        registrado no INPI, capaz de auxiliar na gestão, organização e
        relacionamento dos processos de pagamento, feitos nos consultórios,
        clínicas, hospitais e operadoras de saúde. Aumentando assim, o controle
        sobre o faturamento de honorários , além de facilitar o dia a dia do
        trabalho de seus prestadores de serviço, como a secretária, o contador,
        gestores, entre outros.
      </p>
      <h2>O que solucionamos?</h2>
      <ul>
        <li>Autonomia nos processos.</li>
        <li>Transparência.</li>
        <li>Agilidade na informação.</li>
        <li>Integração com qualquer sistema.</li>
        <li>Redução significativa de retrabalho e perdas.</li>
        <li>
          Aumento do controle, transformando em Gestores todos os envolvidos nos
          processos.
        </li>
      </ul>
      <h2>Sobre o App</h2>
      <p>
        Além da plataforma web os usuários podem contar com o aplicativo WebMed
        para acompanhar os atendimentos, recebimentos, pendências e alertas
        sobre os seus honorários médicos. Para ter acesso ao aplicativo é
        necessário possuir uma conta cadastrada na plataforma WebMed. Caso ainda
        não tenha uma conta, realize o teste grátis por 15 dias acessando{' '}
        <a href="https://webmedbr.com/experimente">webmedbr.com/experimente</a>
      </p>
    </main>
  )
}

export default AboutPage
