import { AxiosResponse } from 'axios'

import { MasterClass } from './master-class'
import { getData } from '../get-data'
import axios from '../../utils/axiosAPI'

class Hospital extends MasterClass {
  /**
   * @override
   */
  async get(): Promise<IHospital[]> {
    let hospitais: IHospital[] = []

    const url = `/hospital${
      this.getIsAdmin() ? `?IDCliente=${this.getIDCliente()}` : ''
    }`

    await getData(url)
      .then(res => {
        hospitais = res as IHospital[]
      })
      .catch(err => {
        console.error({ err })
      })

    hospitais.sort((a, b) => {
      if (a.Hospital > b.Hospital) {
        return 1
      }
      if (a.Hospital < b.Hospital) {
        return -1
      }
      return 0
    })

    return hospitais
  }

  async update(hospital: IHospital): Promise<IHospital | null> {
    let m: IHospital = hospital

    const {
      ID,
      IDCliente,
      Hospital,
      Contato,
      Email,
      CNPJ,
      Endereco,
      Cidade,
      Estado,
      CEP,
      TelefoneFixo,
      TelefoneCelular,
      Observacoes,
      Ex,
      SiglaHospital
    } = hospital

    await axios({
      url: `/hospital/${hospital.ID}`,
      method: 'PUT',
      data: {
        ID,
        IDCliente,
        Hospital,
        Contato,
        Email,
        CNPJ,
        Endereco,
        Cidade,
        Estado,
        CEP,
        TelefoneFixo,
        TelefoneCelular,
        Observacoes,
        Ex,
        SiglaHospital
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res: any) => {
        m = res.data
      })
      .catch((err: any) => {
        console.error({ err })
      })

    return m
  }

  async delete(id: number): Promise<AxiosResponse | null> {
    let response: AxiosResponse | null = null

    await axios
      .delete(`/hospital/${id}`)
      .then(res => {
        response = res.data
      })
      .catch(err => {
        console.error({ err })
      })

    return response
  }
}

export default new Hospital()
