import styled from 'styled-components'

export const ExtratoComponent = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;

  td {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
`
