import styled, { css } from 'styled-components'

export const FlatListComponent = styled.div``

export const ButtonListComponent = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2px;
  overflow: none;
  border-radius: 10px;
`

const selected = css`
  background-color: var(--blue);
  color: var(--lighter-gray);
  box-shadow: 0 0 8px #3339;
  font-weight: bolder;
`

const stoped = css`
  background-color: var(--lighter-gray);
  color: var(--blue);
  font-weight: 500;
`

const isFirst = css`
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
`

const isLast = css`
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
`

export const ButtonComponent = styled.span`
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 9px 18px;
  transition: 0.5s cubic-bezier(0.175, 0.2, 0.32, 1.275);

  ${props => (props.active ? selected : stoped)}
  ${props => (props.isFirst ? isFirst : '')};
  ${props => (props.isLast ? isLast : '')};
`
