import axios from 'axios'

const sandboxURL = 'https://api.sandbox.webmedbr.com'
const serverURL = 'https://api.webmedbr.com'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.location.hostname.includes('sandbox')
      ? sandboxURL
      : serverURL
    : sandboxURL
// const baseURL = serverURL
const token = localStorage.getItem('@webmed-Token')

let headers = {
  'Content-Type': 'application/json'
}

if (token !== undefined && token !== null) {
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }
}

export default axios.create({
  baseURL,
  headers
})
