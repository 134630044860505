import axios from '../../utils/axiosAPI'
import { getData } from '../get-data'
import { MasterClass } from './master-class'

class Producao extends MasterClass implements IProducao {
  // constructor() {
  //   super()
  // }

  public async get(params: InterfaceGetProducao): Promise<any> {
    let url = this.getURL(`producao`)
    let response: InterfaceProducao[] = []

    Object.keys(params).map((i, j) => {
      console.log(`${i}=${params[i]}`)
      url += (url.includes('?') ? '&' : '?') + `${i}=${params[i]}`
    })

    await getData(url).then(res => {
      response = res as InterfaceProducao[]
    })

    return response
  }

  public async getProducaoID(IDProducao): Promise<any> {
    const url = `/producao/${IDProducao}`
    await getData(url)
      .then(res => {
        return res
      })
      .catch(console.warn)
  }

  public async storeProducaoPagto({
    ID,
    Pagamentos
  }: InterfaceStoreProducaoPagto) {
    axios({
      url: `/producao/${ID}`,
      method: 'PUT',
      data: { Pagamentos },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.data
      })
      .catch(err => {
        console.error(`Erro ao tentar lançar pagamento, código de erro: ${err}`)
      })
  }
}

export default new Producao()
